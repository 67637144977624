* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.8) 100%, rgba(0, 0, 0, 0.7) 100%),
    url("../images/main-logo.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-right: 0px;
  padding-bottom: 35px;
  @include media-q($tablet-wide) {
    background: none;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-right: 50px;
    padding-bottom: 0px;
  }
}
li {
  list-style: none;
}

input {
  outline: none;
}

a {
  text-decoration: none;
  color: $dark;
}

.container {
  max-width: 1800px;
  margin: 0 auto;
}

.container-small {
  max-width: 1400px;
  margin: 0 auto;
}

.coloured {
  color: $secondary-color;
}

.video-wrapper {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  @media screen and (min-width: $tablet) {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
}
#myVideo {
  z-index: 1;
}
.video-wrapper-grey {
  z-index: 2;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #292929, $alpha: 0.9);
}

.hidden {
  display: none !important;
}

.fp-tableCell {
  overflow: hidden;
  padding-left: 0px;
  padding-top: 30px;

  @include media-q($phone) {
    padding-left: 0px;
    padding-top: 100px;
  }

  @include media-q($phone-wide) {
    padding-left: 10px;
    padding-top: 100px;
  }
}

.white{
  color: $white;
}
// ANIMATIONS
@keyframes rise {
  0% {
    color: #2e67af;
  }

  100% {
    color: #fcde2b;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
html {
  //For FadeChangePage
  background-color: #000000;
  
}
