@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?5w0k50");
  src: url("../fonts/icomoon.eot?5w0k50#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?5w0k50") format("truetype"), url("../fonts/icomoon.woff?5w0k50") format("woff"), url("../fonts/icomoon.svg?5w0k50#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-pointing-up-hand-svgrepo-com:before {
  content: "\e900"; }

.icon-phone:before {
  content: "\e901"; }

.icon-envelop:before {
  content: "\e945"; }

.icon-location2:before {
  content: "\e948"; }

.icon-facebook2:before {
  content: "\ea91"; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth; }

body {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.8) 100%, rgba(0, 0, 0, 0.7) 100%), url("../images/main-logo.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-right: 0px;
  padding-bottom: 35px; }
  @media screen and (min-width: 1025px) {
    body {
      background: none;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      padding-right: 50px;
      padding-bottom: 0px; } }

li {
  list-style: none; }

input {
  outline: none; }

a {
  text-decoration: none;
  color: #262626; }

.container {
  max-width: 1800px;
  margin: 0 auto; }

.container-small {
  max-width: 1400px;
  margin: 0 auto; }

.coloured {
  color: #ffd500; }

.video-wrapper {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; }
  @media screen and (min-width: 768px) {
    .video-wrapper {
      display: block;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1; } }

#myVideo {
  z-index: 1; }

.video-wrapper-grey {
  z-index: 2;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 41, 41, 0.9); }

.hidden {
  display: none !important; }

.fp-tableCell {
  overflow: hidden;
  padding-left: 0px;
  padding-top: 30px; }
  @media screen and (min-width: 400px) {
    .fp-tableCell {
      padding-left: 0px;
      padding-top: 100px; } }
  @media screen and (min-width: 480px) {
    .fp-tableCell {
      padding-left: 10px;
      padding-top: 100px; } }

.white {
  color: white; }

@keyframes rise {
  0% {
    color: #2e67af; }
  100% {
    color: #fcde2b; } }

@keyframes gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

html {
  background-color: #000000; }

.ajax {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 99999;
  background-color: white;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: 0.4s ease all;
  cursor: pointer; }
  .ajax:hover {
    transition: 0.4s ease all; }
    .ajax:hover .ajax__logo {
      width: 160px; }
  .ajax__logo {
    transition: 0.4s ease all;
    width: 150px; }

.header {
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  height: 100vh;
  background: none;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  @media screen and (min-width: 1025px) {
    .header {
      background-image: none; } }
  .header__container {
    height: 100%; }
  .header__title-box {
    z-index: 2;
    text-align: center;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 0.5px solid #b9b9b9; }
    @media screen and (min-width: 400px) {
      .header__title-box {
        min-height: 120px; } }
    @media screen and (min-width: 560px) {
      .header__title-box {
        padding-bottom: 0px; } }
    @media screen and (min-width: 1025px) {
      .header__title-box {
        padding-left: 20px;
        min-height: 165px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 0.5px solid #b9b9b9; } }
  .header__title {
    padding-top: 0;
    width: 100%;
    z-index: 2;
    padding-left: 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.9375rem;
    font-weight: 400;
    color: white;
    text-align: center;
    padding-bottom: 4px;
    display: block; }
    @media screen and (min-width: 400px) {
      .header__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.375rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 480px) {
      .header__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 560px) {
      .header__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.875rem;
        font-weight: 400;
        color: white;
        display: block; } }
    @media screen and (min-width: 1025px) {
      .header__title {
        padding-top: 30px;
        padding-left: 20px;
        font-family: "Roboto Slab", serif;
        font-size: 1.875rem;
        font-weight: 400;
        color: white;
        padding-bottom: 10px;
        display: block; } }
  .header__content-box {
    padding: 10px;
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }
    @media screen and (min-width: 730px) {
      .header__content-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; } }
  .header__l-box {
    background-image: url("../images/main-logo.jpg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    flex-basis: 40%;
    height: 100%;
    display: none;
    font-family: "Montserrat", sans-serif;
    font-size: 1.875rem;
    font-weight: 600;
    color: #ffd500; }
    @media screen and (min-width: 730px) {
      .header__l-box {
        display: block; } }
    @media screen and (min-width: 1248px) {
      .header__l-box {
        display: block;
        flex-basis: 17%;
        height: 90%;
        font-family: "Montserrat", sans-serif;
        font-size: 1.875rem;
        font-weight: 600;
        color: #ffd500; } }
  .header__number {
    display: block;
    margin-bottom: 20px; }
  .header__r-box {
    padding-left: 20px;
    flex-basis: 60%;
    height: 100%;
    letter-spacing: 4px; }
    @media screen and (min-width: 1248px) {
      .header__r-box {
        flex-basis: 83%;
        height: 100%;
        letter-spacing: 4px; } }
  .header__subtitle {
    font-family: "Roboto Slab", serif;
    font-size: 0.75rem;
    font-weight: 400;
    color: #ffd500;
    margin-bottom: 10px; }
    @media screen and (min-width: 1248px) {
      .header__subtitle {
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: #ffd500;
        margin-bottom: 10px; } }
  .header__text {
    padding-top: 0px;
    letter-spacing: 1px;
    font-family: "Roboto Slab", serif;
    font-size: 0.75rem;
    font-weight: 300;
    color: white;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #b9b9b9; }
    @media screen and (min-width: 730px) {
      .header__text {
        padding-top: 20px;
        letter-spacing: normal;
        font-family: "Roboto Slab", serif;
        font-size: 0.9375rem;
        font-weight: 200;
        color: white;
        padding-bottom: 20px;
        border-bottom: 0.5px solid #b9b9b9; } }
    @media screen and (min-width: 1248px) {
      .header__text {
        padding-top: 5px;
        font-family: "Roboto Slab", serif;
        font-size: 0.875rem;
        font-weight: 400;
        color: white;
        padding-bottom: 5px;
        border-bottom: 0.5px solid #b9b9b9; } }
  .header__text-info {
    margin-bottom: 5px;
    letter-spacing: 1px;
    position: relative;
    font-family: "Montserrat", sans-serif;
    font-size: 0.8125rem;
    font-weight: 300;
    color: white; }
    @media screen and (min-width: 730px) {
      .header__text-info {
        margin-bottom: 5px;
        letter-spacing: normal;
        font-family: "Montserrat", sans-serif;
        font-size: 0.875rem;
        font-weight: 200;
        color: white; } }
    @media screen and (min-width: 1248px) {
      .header__text-info {
        margin-bottom: 5px;
        font-family: "Montserrat", sans-serif;
        font-size: 0.875rem;
        font-weight: 300;
        color: white;
        letter-spacing: 1.5px; } }
    .header__text-info:nth-child(1):before {
      display: none; }
    .header__text-info:nth-child(2):before {
      display: none; }
    .header__text-info:before {
      content: " ";
      display: none;
      width: 6px;
      height: 6px;
      background-color: #ffd500;
      border-radius: 50%;
      position: absolute;
      left: -10px;
      top: calc(50% - 3px); }
      @media screen and (min-width: 480px) {
        .header__text-info:before {
          content: " ";
          display: block;
          width: 6px;
          height: 6px;
          background-color: #ffd500;
          border-radius: 50%;
          position: absolute;
          left: -10px;
          top: calc(50% - 3px); } }
    .header__text-info:nth-child(1) {
      display: none; }
      @media screen and (min-width: 1248px) {
        .header__text-info:nth-child(1) {
          display: block; } }
    .header__text-info:nth-child(2) {
      display: none; }
      @media screen and (min-width: 1248px) {
        .header__text-info:nth-child(2) {
          display: block; } }
  .header__mvs {
    font-family: "Montserrat", sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    color: white; }
    @media screen and (min-width: 1025px) {
      .header__mvs {
        font-family: "Montserrat", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        color: white; } }
  .header__link-wrapper {
    letter-spacing: normal;
    cursor: pointer;
    padding-left: 0px;
    padding-top: 5px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Roboto Slab", serif;
    font-size: 0.9375rem;
    font-weight: 200;
    color: #ffd500;
    padding-bottom: 5px;
    border-bottom: 0.5px solid #b9b9b9;
    transition: 0.4s ease all; }
    @media screen and (min-width: 1025px) {
      .header__link-wrapper:hover {
        padding-left: 10px;
        padding-right: 10px;
        background-color: #ffd500;
        color: #4c9aff; }
      .header__link-wrapper:hover span {
        color: #005aba; } }
    @media screen and (min-width: 1248px) {
      .header__link-wrapper {
        font-family: "Roboto Slab", serif;
        font-size: 1.875rem;
        font-weight: 200;
        color: #ffd500;
        padding-top: 10px;
        padding-bottom: 10px; } }
  .header__finger-svg {
    animation: rise 3s infinite alternate;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    width: 30px;
    height: 30px;
    color: white; }
  .header__link-text {
    display: none; }

.footer {
  position: relative;
  width: 100%;
  min-height: 150px;
  padding: 0 10px;
  background-color: rgba(0, 0, 0, 0.9); }
  @media screen and (min-width: 1025px) {
    .footer {
      padding: 0 50px;
      background-color: rgba(41, 41, 41, 0.9); } }
  .footer__container {
    padding: 20px 0;
    width: 100%;
    height: 100%;
    border-top: 2px solid #b9b9b9; }
  .footer__copyright {
    font-family: "Montserrat", sans-serif;
    font-size: 0.6875rem;
    font-weight: 400;
    color: #b9b9b9;
    text-align: center; }
  .footer__contacts {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px; }
  .footer__contact-item {
    text-align: center;
    flex-basis: 50%; }
    .footer__contact-item:nth-child(odd) {
      text-align: center;
      padding-right: 00px; }
    .footer__contact-item:nth-child(even) {
      text-align: center;
      padding-left: 00px; }
    @media screen and (min-width: 768px) {
      .footer__contact-item {
        text-align: center;
        flex-basis: 50%; } }
  .footer__contact-item a {
    text-align: center;
    transition: 0.4s ease all;
    letter-spacing: 0.5px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.625rem;
    font-weight: 400;
    color: white; }
    @media screen and (min-width: 768px) {
      .footer__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 300;
        color: white;
        letter-spacing: normal; } }
    @media screen and (min-width: 1248px) {
      .footer__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        color: white;
        letter-spacing: normal; } }
    .footer__contact-item a:hover {
      color: #ffd500; }

.nav {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0 65px;
  z-index: 99999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  @media screen and (min-width: 400px) {
    .nav {
      padding: 0 50px; } }
  .nav__wrapper {
    width: 100%;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom: 2px solid #b9b9b9; }
    @media screen and (min-width: 400px) {
      .nav__wrapper {
        padding-top: 0px;
        padding-bottom: 0px; } }
    @media screen and (min-width: 1025px) {
      .nav__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 0px;
        padding-bottom: 0px; } }
  .nav__phone {
    padding-bottom: 5px;
    font-family: "Roboto Slab", serif;
    font-size: 1.0625rem;
    font-weight: 500;
    color: white; }
    .nav__phone a {
      color: white;
      transition: 0.4s ease all; }
    .nav__phone:hover a {
      color: #ffd500; }
    @media screen and (min-width: 400px) {
      .nav__phone {
        font-family: "Roboto Slab", serif;
        font-size: 1.375rem;
        font-weight: 500;
        color: white; } }
  .nav__logo {
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0px; }
    @media screen and (min-width: 400px) {
      .nav__logo {
        padding-top: 0px; } }
    @media screen and (min-width: 480px) {
      .nav__logo {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0px; } }
    @media screen and (min-width: 730px) {
      .nav__logo {
        margin-bottom: 0px; } }
    @media screen and (min-width: 1025px) {
      .nav__logo {
        margin-bottom: 0px; } }
  .nav__logo-image {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 60px;
    margin-right: 10px;
    display: none; }
    @media screen and (min-width: 400px) {
      .nav__logo-image {
        display: block;
        width: 90px;
        margin-right: 30px; } }
  .nav__list {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: 0.4s ease all;
    display: none; }
    @media screen and (min-width: 1025px) {
      .nav__list {
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        transition: 0.4s ease all; } }
  .nav__item {
    text-align: center;
    margin-right: 0px;
    margin-bottom: 15px;
    transition: 0.4s ease all; }
    .nav__item a {
      font-family: "Roboto Slab", serif;
      font-size: 0.9375rem;
      font-weight: 400;
      color: white;
      transition: 0.4s ease all; }
    .nav__item:hover a {
      color: #ffd500; }
    @media screen and (min-width: 730px) {
      .nav__item {
        margin: 0;
        margin-right: 30px;
        font-family: "Montserrat", sans-serif;
        font-size: 1.0625rem;
        font-weight: 500;
        color: white; } }
    .nav__item--form a {
      font-family: "Montserrat", sans-serif;
      font-size: 1.25rem;
      font-weight: 500;
      color: #ffd500;
      padding: 10px 5px;
      border-bottom: 2px solid #ffd500;
      border-left: 2px solid #ffd500;
      border-right: 2px solid transparent;
      border-top: 2px solid transparent;
      transition: 0.4s ease all; }
      .nav__item--form a:hover {
        border-bottom: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid #ffd500;
        border-top: 2px solid #ffd500; }
    .nav__item:last-of-type a {
      font-weight: 600; }

table {
  width: 100%;
  font-family: "Roboto Slab", serif;
  font-size: 12px;
  border-radius: 10px;
  border-spacing: 0;
  text-align: center; }
  @media screen and (min-width: 768px) {
    table {
      font-size: 15px; } }

th {
  background: #ffd500;
  color: white;
  text-shadow: 0 1px 1px #2D2020;
  padding: 10px 20px; }

th, td {
  border-style: solid;
  border-width: 0 1px 1px 0;
  border-color: #b9b9b9; }

th:first-child, td:first-child {
  text-align: left; }

th:first-child {
  border-top-left-radius: 10px; }

th:last-child {
  border-top-right-radius: 10px;
  border-right: none; }

td {
  text-align: left;
  padding: 10px 20px;
  background: white; }

tr:last-child td:first-child {
  border-radius: 0 0 0 10px; }

tr:last-child td:last-child {
  border-radius: 0 0 10px 0; }

tr td:last-child {
  border-right: none; }

.first {
  padding-left: 10px;
  padding-right: 5px;
  width: 100%;
  height: 100vh; }
  .first__title {
    z-index: 2;
    font-family: "Montserrat", sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    color: white;
    text-align: center; }
    @media screen and (min-width: 480px) {
      .first__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 560px) {
      .first__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.5625rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 1025px) {
      .first__title {
        font-family: "Roboto Slab", serif;
        font-size: 3.75rem;
        font-weight: 400;
        color: white; } }
  .first__content-box {
    padding: 10px 0px 10px 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    @media screen and (min-width: 1248px) {
      .first__content-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start; } }
  .first__l-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    flex-basis: 10%;
    height: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: 1.875rem;
    font-weight: 600;
    color: white; }
    @media screen and (min-width: 1248px) {
      .first__l-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-basis: 40%;
        height: 100%;
        font-family: "Montserrat", sans-serif;
        font-size: 1.875rem;
        font-weight: 600;
        color: white; } }
  .first__number {
    display: block;
    margin-bottom: 20px; }
  .first__r-box {
    padding-left: 10px;
    flex-basis: 90%;
    height: 100%;
    letter-spacing: 4px; }
    @media screen and (min-width: 1248px) {
      .first__r-box {
        flex-basis: 60%;
        height: 100%;
        letter-spacing: 4px; } }
  .first__subtitle {
    letter-spacing: normal;
    cursor: pointer;
    padding-left: 10px;
    padding-top: 2px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Roboto Slab", serif;
    font-size: 0.9375rem;
    font-weight: 200;
    color: #ffd500;
    padding-bottom: 2px;
    border-bottom: 0.5px solid #b9b9b9;
    transition: 0.4s ease all; }
    @media screen and (min-width: 400px) {
      .first__subtitle {
        padding-bottom: 5px;
        padding-top: 5px; } }
    @media screen and (min-width: 1025px) {
      .first__subtitle:hover {
        padding-left: 10px;
        padding-right: 10px;
        background-color: #ffd500;
        color: #4c9aff; }
      .first__subtitle:hover span {
        color: #005aba; } }
    @media screen and (min-width: 1248px) {
      .first__subtitle {
        font-family: "Roboto Slab", serif;
        font-size: 1.875rem;
        font-weight: 200;
        color: #ffd500;
        padding-top: 12px;
        padding-bottom: 12px; } }
  .first__subtext {
    transition: 0.4s ease all;
    padding: 2px 10px 2px 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.75rem;
    font-weight: 300;
    color: white;
    word-spacing: 0.8px;
    border-bottom: 0.5px solid #b9b9b9;
    letter-spacing: 0.4px;
    line-height: 13px;
    display: none; }
    @media screen and (min-width: 480px) {
      .first__subtext {
        display: block;
        line-height: 15px;
        padding: 20px 10px 20px 10px;
        font-family: "Montserrat", sans-serif;
        font-size: 0.75rem;
        font-weight: 200;
        color: white; } }
    @media screen and (min-width: 768px) {
      .first__subtext {
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 200;
        color: white;
        border-bottom: 0.5px solid #b9b9b9;
        letter-spacing: 0.5px; } }
    @media screen and (min-width: 1248px) {
      .first__subtext {
        font-family: "Montserrat", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        color: white;
        border-bottom: 0.5px solid #b9b9b9; } }
  .first__finger-svg {
    animation: rise 3s infinite alternate;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    width: 30px;
    height: 30px;
    color: white; }
  .first__text {
    transition: 0.4s ease all;
    padding: 10px 10px 10px 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.8125rem;
    font-weight: 300;
    color: white;
    word-spacing: 0.8px;
    border-bottom: 0.5px solid #b9b9b9;
    letter-spacing: 0.4px;
    line-height: 13px; }
    @media screen and (min-width: 480px) {
      .first__text {
        line-height: 15px;
        padding: 20px 10px 20px 10px;
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 200;
        color: white; } }
    @media screen and (min-width: 768px) {
      .first__text {
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 200;
        color: white;
        border-bottom: 0.5px solid #b9b9b9;
        letter-spacing: 0.5px; } }
    @media screen and (min-width: 1248px) {
      .first__text {
        font-family: "Montserrat", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        color: white;
        border-bottom: 0.5px solid #b9b9b9; } }
  .first__link-text {
    display: none; }
  .first__contact {
    padding-top: 2px;
    padding-left: 10px;
    font-family: "Roboto Slab", serif;
    font-size: 0.9375rem;
    font-weight: 300;
    color: #ffd500;
    margin-bottom: 2px; }
    @media screen and (min-width: 480px) {
      .first__contact {
        padding-top: 10px;
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 300;
        color: #ffd500;
        margin-bottom: 5px; } }
    @media screen and (min-width: 1248px) {
      .first__contact {
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 600;
        color: #ffd500;
        margin-bottom: 5px; } }
  .first__contact-item {
    text-align: center;
    margin-bottom: 0px;
    flex-basis: 50%; }
    @media screen and (min-width: 480px) {
      .first__contact-item {
        text-align: center;
        margin-bottom: 5px;
        flex-basis: 50%; } }
    @media screen and (min-width: 1248px) {
      .first__contact-item {
        margin-bottom: 5px;
        flex-basis: 30%; } }
  .first__contact-item a {
    text-align: center;
    transition: 0.4s ease all;
    letter-spacing: 0.5px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.625rem;
    font-weight: 400;
    color: white; }
    @media screen and (min-width: 768px) {
      .first__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 300;
        color: white;
        letter-spacing: normal; } }
    @media screen and (min-width: 1248px) {
      .first__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        color: white;
        letter-spacing: normal; } }
    .first__contact-item a:hover {
      color: #ffd500; }
  .first__contact-list {
    padding-left: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; }
  .first__image {
    display: none; }
    @media screen and (min-width: 1248px) {
      .first__image {
        display: block;
        height: auto;
        width: 100%; } }
    @media screen and (min-width: 1440px) {
      .first__image {
        display: block;
        height: 500px;
        width: 100%; } }

.second {
  padding-left: 10px;
  padding-right: 5px;
  width: 100%;
  height: 100vh; }
  .second__title {
    z-index: 2;
    font-family: "Montserrat", sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    color: white;
    text-align: center; }
    @media screen and (min-width: 480px) {
      .second__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 560px) {
      .second__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.5625rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 1025px) {
      .second__title {
        font-family: "Roboto Slab", serif;
        font-size: 3.75rem;
        font-weight: 400;
        color: white; } }
  .second__content-box {
    padding: 10px 0px 10px 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    @media screen and (min-width: 1248px) {
      .second__content-box {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start; } }
  .second__l-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    flex-basis: 10%;
    height: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: 1.875rem;
    font-weight: 600;
    color: white; }
    @media screen and (min-width: 1248px) {
      .second__l-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-basis: 40%;
        height: 100%;
        font-family: "Montserrat", sans-serif;
        font-size: 1.875rem;
        font-weight: 600;
        color: white; } }
  .second__number {
    display: block;
    margin-bottom: 20px; }
  .second__r-box {
    padding-right: 0px;
    flex-basis: 90%;
    height: 100%;
    letter-spacing: 4px; }
    @media screen and (min-width: 1248px) {
      .second__r-box {
        flex-basis: 60%;
        height: 100%;
        letter-spacing: 4px; } }
  .second__subtitle {
    letter-spacing: normal;
    cursor: pointer;
    padding-right: 0px;
    padding-top: 2px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Roboto Slab", serif;
    font-size: 0.9375rem;
    font-weight: 200;
    color: #ffd500;
    padding-bottom: 2px;
    border-bottom: 0.5px solid #b9b9b9;
    transition: 0.4s ease all; }
    @media screen and (min-width: 400px) {
      .second__subtitle {
        padding-bottom: 5px;
        padding-top: 5px; } }
    @media screen and (min-width: 1025px) {
      .second__subtitle:hover {
        padding-left: 10px;
        padding-right: 10px;
        background-color: #ffd500;
        color: #4c9aff; }
      .second__subtitle:hover span {
        color: #005aba; } }
    @media screen and (min-width: 1248px) {
      .second__subtitle {
        font-family: "Roboto Slab", serif;
        font-size: 1.875rem;
        font-weight: 200;
        color: #ffd500;
        padding-top: 12px;
        padding-bottom: 12px; } }
  .second__finger-svg {
    animation: rise 3s infinite alternate;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    width: 30px;
    height: 30px;
    color: white; }
  .second__text {
    transition: 0.4s ease all;
    padding: 10px 10px 10px 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.8125rem;
    font-weight: 300;
    color: white;
    word-spacing: 0.8px;
    border-bottom: 0.5px solid #b9b9b9;
    letter-spacing: 0.4px;
    line-height: 13px; }
    @media screen and (min-width: 480px) {
      .second__text {
        line-height: 15px;
        padding: 20px 10px 20px 10px;
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 200;
        color: white; } }
    @media screen and (min-width: 768px) {
      .second__text {
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 200;
        color: white;
        border-bottom: 0.5px solid #b9b9b9;
        letter-spacing: 0.5px; } }
    @media screen and (min-width: 1248px) {
      .second__text {
        font-family: "Montserrat", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        color: white;
        border-bottom: 0.5px solid #b9b9b9; } }
  .second__link-text {
    display: none; }
  .second__contact {
    padding-top: 2px;
    padding-left: 10px;
    font-family: "Roboto Slab", serif;
    font-size: 0.9375rem;
    font-weight: 300;
    color: #ffd500;
    margin-bottom: 2px; }
    @media screen and (min-width: 480px) {
      .second__contact {
        padding-top: 10px;
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 300;
        color: #ffd500;
        margin-bottom: 5px; } }
    @media screen and (min-width: 1248px) {
      .second__contact {
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 600;
        color: #ffd500;
        margin-bottom: 5px; } }
  .second__contact-item {
    text-align: center;
    margin-bottom: 0px;
    flex-basis: 50%; }
    @media screen and (min-width: 480px) {
      .second__contact-item {
        text-align: center;
        margin-bottom: 5px;
        flex-basis: 50%; } }
    @media screen and (min-width: 1248px) {
      .second__contact-item {
        margin-bottom: 5px;
        flex-basis: 30%; } }
  .second__contact-item a {
    text-align: center;
    transition: 0.4s ease all;
    letter-spacing: 0.5px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.625rem;
    font-weight: 400;
    color: white; }
    @media screen and (min-width: 768px) {
      .second__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 300;
        color: white;
        letter-spacing: normal; } }
    @media screen and (min-width: 1248px) {
      .second__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        color: white;
        letter-spacing: normal; } }
    .second__contact-item a:hover {
      color: #ffd500; }
  .second__contact-list {
    padding-left: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; }
  .second__image {
    display: none; }
    @media screen and (min-width: 1248px) {
      .second__image {
        display: block;
        width: 100%; } }

.third {
  padding-left: 10px;
  padding-right: 5px;
  width: 100%;
  height: 100vh; }
  .third__title {
    z-index: 2;
    font-family: "Montserrat", sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    color: white;
    text-align: center; }
    @media screen and (min-width: 480px) {
      .third__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 560px) {
      .third__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.5625rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 1025px) {
      .third__title {
        font-family: "Roboto Slab", serif;
        font-size: 3.75rem;
        font-weight: 400;
        color: white; } }
  .third__content-box {
    padding: 10px 0px 10px 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    @media screen and (min-width: 1248px) {
      .third__content-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start; } }
  .third__l-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    flex-basis: 10%;
    height: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: 1.875rem;
    font-weight: 600;
    color: white; }
    @media screen and (min-width: 1248px) {
      .third__l-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-basis: 40%;
        height: 100%;
        font-family: "Montserrat", sans-serif;
        font-size: 1.875rem;
        font-weight: 600;
        color: white; } }
  .third__number {
    display: block;
    margin-bottom: 20px; }
  .third__r-box {
    padding-left: 10px;
    flex-basis: 90%;
    height: 100%;
    letter-spacing: 4px; }
    @media screen and (min-width: 1248px) {
      .third__r-box {
        flex-basis: 60%;
        height: 100%;
        letter-spacing: 4px; } }
  .third__subtitle {
    letter-spacing: normal;
    cursor: pointer;
    padding-left: 10px;
    padding-top: 2px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Roboto Slab", serif;
    font-size: 0.875rem;
    font-weight: 200;
    color: #ffd500;
    padding-bottom: 2px;
    border-bottom: 0.5px solid #b9b9b9;
    transition: 0.4s ease all; }
    @media screen and (min-width: 400px) {
      .third__subtitle {
        padding-bottom: 5px;
        padding-top: 5px; } }
    @media screen and (min-width: 1025px) {
      .third__subtitle:hover {
        padding-left: 10px;
        padding-right: 10px;
        background-color: #ffd500;
        color: #4c9aff; }
      .third__subtitle:hover span {
        color: #005aba; } }
    @media screen and (min-width: 1248px) {
      .third__subtitle {
        font-family: "Roboto Slab", serif;
        font-size: 1.5625rem;
        font-weight: 200;
        color: #ffd500;
        padding-top: 12px;
        padding-bottom: 12px; } }
  .third__subtext {
    transition: 0.4s ease all;
    padding: 2px 10px 2px 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.8125rem;
    font-weight: 300;
    color: white;
    word-spacing: 0.8px;
    border-bottom: 0.5px solid #b9b9b9;
    letter-spacing: 0.4px;
    line-height: 13px; }
    @media screen and (min-width: 480px) {
      .third__subtext {
        line-height: 15px;
        padding: 20px 10px 20px 10px;
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 200;
        color: white; } }
    @media screen and (min-width: 768px) {
      .third__subtext {
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 200;
        color: white;
        border-bottom: 0.5px solid #b9b9b9;
        letter-spacing: 0.5px; } }
    @media screen and (min-width: 1248px) {
      .third__subtext {
        font-family: "Montserrat", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        color: white;
        border-bottom: 0.5px solid #b9b9b9; } }
  .third__finger-svg {
    animation: rise 3s infinite alternate;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    width: 30px;
    height: 30px;
    color: white; }
  .third__text p {
    transition: 0.4s ease all;
    padding: 5px 5px 5px 15px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.75rem;
    font-weight: 300;
    color: white;
    word-spacing: 0.8px;
    border-bottom: 0.5px solid #b9b9b9;
    letter-spacing: 0.4px; }
    @media screen and (min-width: 480px) {
      .third__text p {
        line-height: 17px;
        padding: 10px 10px 10px 10px;
        font-family: "Montserrat", sans-serif;
        font-size: 0.8125rem;
        font-weight: 200;
        color: white; } }
    @media screen and (min-width: 768px) {
      .third__text p {
        font-family: "Montserrat", sans-serif;
        font-size: 0.8125rem;
        font-weight: 200;
        color: white;
        border-bottom: 0.5px solid #b9b9b9;
        letter-spacing: 0.5px; } }
    @media screen and (min-width: 1248px) {
      .third__text p {
        font-family: "Montserrat", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        color: white;
        border-bottom: 0.5px solid #b9b9b9; } }
  .third__link-wrapper {
    cursor: pointer;
    padding-left: 0px;
    padding-top: 15px;
    font-family: "Roboto Slab", serif;
    font-size: 1.25rem;
    font-weight: 200;
    color: #ffd500;
    padding-bottom: 15px;
    border-bottom: 0.5px solid #b9b9b9;
    transition: 0.4s ease all; }
    @media screen and (min-width: 1248px) {
      .third__link-wrapper {
        font-family: "Roboto Slab", serif;
        font-size: 1.875rem;
        font-weight: 200;
        color: #ffd500;
        padding-top: 30px;
        padding-bottom: 30px; } }
    .third__link-wrapper:hover {
      padding-left: 10px;
      background-color: white;
      color: #2684ff; }
  .third__link-text {
    display: none; }
  .third__contact {
    padding-top: 2px;
    padding-left: 10px;
    font-family: "Roboto Slab", serif;
    font-size: 0.9375rem;
    font-weight: 300;
    color: #ffd500;
    margin-bottom: 2px; }
    @media screen and (min-width: 480px) {
      .third__contact {
        padding-top: 10px;
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 300;
        color: #ffd500;
        margin-bottom: 5px; } }
    @media screen and (min-width: 1248px) {
      .third__contact {
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 600;
        color: #ffd500;
        margin-bottom: 5px; } }
  .third__contact-item {
    text-align: center;
    margin-bottom: 0px;
    flex-basis: 50%; }
    @media screen and (min-width: 480px) {
      .third__contact-item {
        text-align: center;
        margin-bottom: 5px;
        flex-basis: 50%; } }
    @media screen and (min-width: 1248px) {
      .third__contact-item {
        margin-bottom: 5px;
        flex-basis: 30%; } }
  .third__contact-item a {
    text-align: center;
    transition: 0.4s ease all;
    letter-spacing: 0.5px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    color: white; }
    @media screen and (min-width: 768px) {
      .third__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 300;
        color: white;
        letter-spacing: normal; } }
    @media screen and (min-width: 1248px) {
      .third__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        color: white;
        letter-spacing: normal; } }
    .third__contact-item a:hover {
      color: #ffd500; }
  .third__contact-list {
    padding-left: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; }
  .third__image {
    display: none; }
    @media screen and (min-width: 1248px) {
      .third__image {
        display: block;
        width: 100%; } }

.fourth {
  padding-left: 10px;
  padding-right: 5px;
  width: 100%;
  height: 100vh; }
  .fourth__title {
    z-index: 2;
    font-family: "Montserrat", sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    color: white;
    text-align: center; }
    @media screen and (min-width: 480px) {
      .fourth__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 560px) {
      .fourth__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.5625rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 1025px) {
      .fourth__title {
        font-family: "Roboto Slab", serif;
        font-size: 3.75rem;
        font-weight: 400;
        color: white; } }
  .fourth__secondtitle {
    letter-spacing: normal;
    text-align: center;
    padding-right: 0px;
    padding-top: 2px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Roboto Slab", serif;
    font-size: 0.9375rem;
    font-weight: 200;
    color: white;
    padding-bottom: 5px;
    border-bottom: 0.5px solid #b9b9b9;
    transition: 0.4s ease all; }
    @media screen and (min-width: 400px) {
      .fourth__secondtitle {
        padding-bottom: 5px;
        padding-top: 5px; } }
    @media screen and (min-width: 1248px) {
      .fourth__secondtitle {
        font-family: "Roboto Slab", serif;
        font-size: 1.375rem;
        font-weight: 200;
        color: white;
        padding-top: 12px;
        padding-bottom: 12px; } }
  .fourth__content-box {
    padding: 10px 0px 10px 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    @media screen and (min-width: 1248px) {
      .fourth__content-box {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start; } }
  .fourth__l-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    flex-basis: 10%;
    height: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: 1.875rem;
    font-weight: 600;
    color: white; }
    @media screen and (min-width: 1248px) {
      .fourth__l-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-basis: 40%;
        height: 100%;
        font-family: "Montserrat", sans-serif;
        font-size: 1.875rem;
        font-weight: 600;
        color: white; } }
  .fourth__number {
    display: block;
    margin-bottom: 20px; }
  .fourth__r-box {
    padding-right: 0px;
    flex-basis: 90%;
    height: 100%;
    letter-spacing: 4px; }
    @media screen and (min-width: 1248px) {
      .fourth__r-box {
        flex-basis: 60%;
        height: 100%;
        letter-spacing: 4px; } }
  .fourth__subtitle {
    letter-spacing: normal;
    cursor: pointer;
    padding-right: 0px;
    padding-top: 2px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Roboto Slab", serif;
    font-size: 0.9375rem;
    font-weight: 200;
    color: #ffd500;
    padding-bottom: 2px;
    border-bottom: 0.5px solid #b9b9b9;
    transition: 0.4s ease all; }
    @media screen and (min-width: 400px) {
      .fourth__subtitle {
        padding-bottom: 5px;
        padding-top: 5px; } }
    @media screen and (min-width: 1025px) {
      .fourth__subtitle:hover {
        padding-left: 10px;
        padding-right: 10px;
        background-color: #ffd500;
        color: #4c9aff; }
      .fourth__subtitle:hover span {
        color: #005aba; } }
    @media screen and (min-width: 1248px) {
      .fourth__subtitle {
        font-family: "Roboto Slab", serif;
        font-size: 1.875rem;
        font-weight: 200;
        color: #ffd500;
        padding-top: 12px;
        padding-bottom: 12px; } }
  .fourth__finger-svg {
    animation: rise 3s infinite alternate;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    width: 30px;
    height: 30px;
    color: white; }
  .fourth__text p {
    transition: 0.4s ease all;
    padding: 5px 5px 5px 15px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.8125rem;
    font-weight: 300;
    color: white;
    border-bottom: 0.5px solid #b9b9b9;
    letter-spacing: 0.5px;
    line-height: 12px; }
    @media screen and (min-width: 480px) {
      .fourth__text p {
        line-height: 17px;
        padding: 10px 10px 10px 10px;
        font-family: "Montserrat", sans-serif;
        font-size: 0.8125rem;
        font-weight: 200;
        color: white; } }
    @media screen and (min-width: 768px) {
      .fourth__text p {
        font-family: "Montserrat", sans-serif;
        font-size: 0.8125rem;
        font-weight: 200;
        color: white;
        border-bottom: 0.5px solid #b9b9b9;
        letter-spacing: 0.5px; } }
    @media screen and (min-width: 1248px) {
      .fourth__text p {
        font-family: "Montserrat", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        color: white;
        border-bottom: 0.5px solid #b9b9b9; } }
  .fourth__link-wrapper {
    cursor: pointer;
    padding-right: 0px;
    padding-top: 15px;
    font-family: "Roboto Slab", serif;
    font-size: 1.25rem;
    font-weight: 200;
    color: #ffd500;
    padding-bottom: 15px;
    border-bottom: 0.5px solid #b9b9b9;
    transition: 0.4s ease all; }
    @media screen and (min-width: 1248px) {
      .fourth__link-wrapper {
        font-family: "Roboto Slab", serif;
        font-size: 1.875rem;
        font-weight: 200;
        color: #ffd500;
        padding-top: 30px;
        padding-bottom: 30px; } }
    .fourth__link-wrapper:hover {
      padding-right: 10px;
      background-color: white;
      color: #4c9aff; }
  .fourth__link-text {
    display: none; }
  .fourth__contact {
    padding-top: 2px;
    padding-left: 10px;
    font-family: "Roboto Slab", serif;
    font-size: 0.9375rem;
    font-weight: 300;
    color: #ffd500;
    margin-bottom: 2px; }
    @media screen and (min-width: 480px) {
      .fourth__contact {
        padding-top: 10px;
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 300;
        color: #ffd500;
        margin-bottom: 5px; } }
    @media screen and (min-width: 1248px) {
      .fourth__contact {
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 600;
        color: #ffd500;
        margin-bottom: 5px; } }
  .fourth__contact-item {
    text-align: center;
    margin-bottom: 0px;
    flex-basis: 50%; }
    @media screen and (min-width: 480px) {
      .fourth__contact-item {
        text-align: center;
        margin-bottom: 5px;
        flex-basis: 50%; } }
    @media screen and (min-width: 1248px) {
      .fourth__contact-item {
        margin-bottom: 5px;
        flex-basis: 30%; } }
  .fourth__contact-item a {
    text-align: center;
    transition: 0.4s ease all;
    letter-spacing: 0.5px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.625rem;
    font-weight: 400;
    color: white; }
    @media screen and (min-width: 768px) {
      .fourth__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 300;
        color: white;
        letter-spacing: normal; } }
    @media screen and (min-width: 1248px) {
      .fourth__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        color: white;
        letter-spacing: normal; } }
    .fourth__contact-item a:hover {
      color: #ffd500; }
  .fourth__contact-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 10px; }
  .fourth__image {
    display: none; }
    @media screen and (min-width: 1248px) {
      .fourth__image {
        display: block;
        width: 100%; } }

.fiveth {
  padding-left: 10px;
  padding-right: 5px;
  width: 100%;
  height: 100vh; }
  .fiveth__title {
    z-index: 2;
    font-family: "Montserrat", sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    color: white;
    text-align: center; }
    @media screen and (min-width: 480px) {
      .fiveth__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 560px) {
      .fiveth__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.5625rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 1025px) {
      .fiveth__title {
        font-family: "Roboto Slab", serif;
        font-size: 3.75rem;
        font-weight: 400;
        color: white; } }
  .fiveth__content-box {
    padding: 10px 0px 10px 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    @media screen and (min-width: 1248px) {
      .fiveth__content-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start; } }
  .fiveth__l-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    flex-basis: 10%;
    height: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: 1.875rem;
    font-weight: 600;
    color: white; }
    @media screen and (min-width: 1248px) {
      .fiveth__l-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-basis: 40%;
        height: 100%;
        font-family: "Montserrat", sans-serif;
        font-size: 1.875rem;
        font-weight: 600;
        color: white; } }
  .fiveth__number {
    display: block;
    margin-bottom: 20px; }
  .fiveth__r-box {
    padding-left: 10px;
    flex-basis: 90%;
    height: 100%;
    letter-spacing: 4px; }
    @media screen and (min-width: 1248px) {
      .fiveth__r-box {
        flex-basis: 60%;
        height: 100%;
        letter-spacing: 4px; } }
  .fiveth__subtitle {
    letter-spacing: normal;
    cursor: pointer;
    padding-left: 10px;
    padding-top: 2px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Roboto Slab", serif;
    font-size: 0.9375rem;
    font-weight: 200;
    color: #ffd500;
    padding-bottom: 2px;
    border-bottom: 0.5px solid #b9b9b9;
    transition: 0.4s ease all; }
    @media screen and (min-width: 400px) {
      .fiveth__subtitle {
        padding-bottom: 5px;
        padding-top: 5px; } }
    @media screen and (min-width: 1025px) {
      .fiveth__subtitle:hover {
        padding-left: 10px;
        padding-right: 10px;
        background-color: #ffd500;
        color: #4c9aff; }
      .fiveth__subtitle:hover span {
        color: #005aba; } }
    @media screen and (min-width: 1248px) {
      .fiveth__subtitle {
        font-family: "Roboto Slab", serif;
        font-size: 1.5625rem;
        font-weight: 200;
        color: #ffd500;
        padding-top: 12px;
        padding-bottom: 12px; } }
  .fiveth__finger-svg {
    animation: rise 3s infinite alternate;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    width: 30px;
    height: 30px;
    color: white; }
  .fiveth__text p {
    transition: 0.4s ease all;
    padding: 5px 5px 5px 15px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.8125rem;
    font-weight: 300;
    color: white;
    word-spacing: 0.8px;
    border-bottom: 0.5px solid #b9b9b9;
    letter-spacing: 0.4px; }
    @media screen and (min-width: 480px) {
      .fiveth__text p {
        line-height: 17px;
        padding: 10px 10px 10px 10px;
        font-family: "Montserrat", sans-serif;
        font-size: 0.8125rem;
        font-weight: 200;
        color: white; } }
    @media screen and (min-width: 768px) {
      .fiveth__text p {
        font-family: "Montserrat", sans-serif;
        font-size: 0.8125rem;
        font-weight: 200;
        color: white;
        border-bottom: 0.5px solid #b9b9b9;
        letter-spacing: 0.5px; } }
    @media screen and (min-width: 1248px) {
      .fiveth__text p {
        font-family: "Montserrat", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        color: white;
        border-bottom: 0.5px solid #b9b9b9; } }
  .fiveth__link-wrapper {
    cursor: pointer;
    padding-left: 0px;
    padding-top: 15px;
    font-family: "Roboto Slab", serif;
    font-size: 1.25rem;
    font-weight: 200;
    color: #ffd500;
    padding-bottom: 15px;
    border-bottom: 0.5px solid #b9b9b9;
    transition: 0.4s ease all; }
    @media screen and (min-width: 1248px) {
      .fiveth__link-wrapper {
        font-family: "Roboto Slab", serif;
        font-size: 1.875rem;
        font-weight: 200;
        color: #ffd500;
        padding-top: 30px;
        padding-bottom: 30px; } }
    .fiveth__link-wrapper:hover {
      padding-left: 10px;
      background-color: white;
      color: #2684ff; }
  .fiveth__link-text {
    display: none; }
  .fiveth__contact {
    padding-top: 2px;
    padding-left: 10px;
    font-family: "Roboto Slab", serif;
    font-size: 0.9375rem;
    font-weight: 300;
    color: #ffd500;
    margin-bottom: 2px; }
    @media screen and (min-width: 480px) {
      .fiveth__contact {
        padding-top: 10px;
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 300;
        color: #ffd500;
        margin-bottom: 5px; } }
    @media screen and (min-width: 1248px) {
      .fiveth__contact {
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 600;
        color: #ffd500;
        margin-bottom: 5px; } }
  .fiveth__contact-item {
    text-align: center;
    margin-bottom: 0px;
    flex-basis: 50%; }
    @media screen and (min-width: 480px) {
      .fiveth__contact-item {
        text-align: center;
        margin-bottom: 5px;
        flex-basis: 50%; } }
    @media screen and (min-width: 1248px) {
      .fiveth__contact-item {
        margin-bottom: 5px;
        flex-basis: 30%; } }
  .fiveth__contact-item a {
    text-align: center;
    transition: 0.4s ease all;
    letter-spacing: 0.5px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    color: white; }
    @media screen and (min-width: 768px) {
      .fiveth__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 300;
        color: white;
        letter-spacing: normal; } }
    @media screen and (min-width: 1248px) {
      .fiveth__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        color: white;
        letter-spacing: normal; } }
    .fiveth__contact-item a:hover {
      color: #ffd500; }
  .fiveth__contact-list {
    padding-left: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; }
  .fiveth__image {
    display: none; }
    @media screen and (min-width: 1248px) {
      .fiveth__image {
        display: block;
        width: 100%; } }

.sixth {
  padding-left: 10px;
  padding-right: 5px;
  width: 100%;
  height: 100vh; }
  .sixth__title {
    z-index: 2;
    font-family: "Montserrat", sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    color: white;
    text-align: center; }
    @media screen and (min-width: 480px) {
      .sixth__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 560px) {
      .sixth__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.5625rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 1025px) {
      .sixth__title {
        font-family: "Roboto Slab", serif;
        font-size: 2.8125rem;
        font-weight: 400;
        color: white; } }
  .sixth__content-box {
    padding: 10px 0px 10px 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    @media screen and (min-width: 1248px) {
      .sixth__content-box {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start; } }
  .sixth__l-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    flex-basis: 10%;
    height: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: 1.875rem;
    font-weight: 600;
    color: white; }
    @media screen and (min-width: 1248px) {
      .sixth__l-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-basis: 40%;
        height: 100%;
        font-family: "Montserrat", sans-serif;
        font-size: 1.875rem;
        font-weight: 600;
        color: white; } }
  .sixth__number {
    display: block;
    margin-bottom: 20px; }
  .sixth__r-box {
    padding-right: 0px;
    flex-basis: 90%;
    height: 100%;
    letter-spacing: 4px; }
    @media screen and (min-width: 1248px) {
      .sixth__r-box {
        flex-basis: 60%;
        height: 100%;
        letter-spacing: 4px; } }
  .sixth__subtitle {
    letter-spacing: normal;
    cursor: pointer;
    padding-right: 0px;
    padding-top: 2px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Roboto Slab", serif;
    font-size: 0.9375rem;
    font-weight: 200;
    color: #ffd500;
    padding-bottom: 2px;
    border-bottom: 0.5px solid #b9b9b9;
    transition: 0.4s ease all; }
    @media screen and (min-width: 400px) {
      .sixth__subtitle {
        padding-bottom: 5px;
        padding-top: 5px; } }
    @media screen and (min-width: 1025px) {
      .sixth__subtitle:hover {
        padding-left: 10px;
        padding-right: 10px;
        background-color: #ffd500;
        color: #4c9aff; }
      .sixth__subtitle:hover span {
        color: #005aba; } }
    @media screen and (min-width: 1248px) {
      .sixth__subtitle {
        font-family: "Roboto Slab", serif;
        font-size: 1.875rem;
        font-weight: 200;
        color: #ffd500;
        padding-top: 12px;
        padding-bottom: 12px; } }
  .sixth__finger-svg {
    animation: rise 3s infinite alternate;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    width: 30px;
    height: 30px;
    color: white; }
  .sixth__text p {
    transition: 0.4s ease all;
    padding: 5px 5px 5px 15px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.8125rem;
    font-weight: 300;
    color: white;
    border-bottom: 0.5px solid #b9b9b9;
    letter-spacing: 0.5px;
    line-height: 12px; }
    @media screen and (min-width: 480px) {
      .sixth__text p {
        line-height: 17px;
        padding: 10px 10px 10px 10px;
        font-family: "Montserrat", sans-serif;
        font-size: 0.8125rem;
        font-weight: 200;
        color: white; } }
    @media screen and (min-width: 768px) {
      .sixth__text p {
        font-family: "Montserrat", sans-serif;
        font-size: 0.8125rem;
        font-weight: 200;
        color: white;
        border-bottom: 0.5px solid #b9b9b9;
        letter-spacing: 0.5px; } }
    @media screen and (min-width: 1248px) {
      .sixth__text p {
        font-family: "Montserrat", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        color: white;
        border-bottom: 0.5px solid #b9b9b9; } }
  .sixth__link-text {
    display: none; }
  .sixth__contact {
    padding-top: 2px;
    padding-left: 10px;
    font-family: "Roboto Slab", serif;
    font-size: 0.9375rem;
    font-weight: 300;
    color: #ffd500;
    margin-bottom: 2px; }
    @media screen and (min-width: 480px) {
      .sixth__contact {
        padding-top: 10px;
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 300;
        color: #ffd500;
        margin-bottom: 5px; } }
    @media screen and (min-width: 1248px) {
      .sixth__contact {
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 600;
        color: #ffd500;
        margin-bottom: 5px; } }
  .sixth__contact-item {
    text-align: center;
    margin-bottom: 0px;
    flex-basis: 50%; }
    @media screen and (min-width: 480px) {
      .sixth__contact-item {
        text-align: center;
        margin-bottom: 5px;
        flex-basis: 50%; } }
    @media screen and (min-width: 1248px) {
      .sixth__contact-item {
        margin-bottom: 5px;
        flex-basis: 30%; } }
  .sixth__contact-item a {
    text-align: center;
    transition: 0.4s ease all;
    letter-spacing: 0.5px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.625rem;
    font-weight: 400;
    color: white; }
    @media screen and (min-width: 768px) {
      .sixth__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 300;
        color: white;
        letter-spacing: normal; } }
    @media screen and (min-width: 1248px) {
      .sixth__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        color: white;
        letter-spacing: normal; } }
    .sixth__contact-item a:hover {
      color: #ffd500; }
  .sixth__contact-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 10px; }
  .sixth__image {
    max-height: 450px;
    display: none; }
    @media screen and (min-width: 1248px) {
      .sixth__image {
        display: block;
        width: 100%; } }

.seventh {
  padding-left: 10px;
  padding-right: 5px;
  width: 100%;
  height: 100vh; }
  .seventh__title {
    z-index: 2;
    font-family: "Montserrat", sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    color: white;
    text-align: center; }
    @media screen and (min-width: 480px) {
      .seventh__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 560px) {
      .seventh__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.5625rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 1025px) {
      .seventh__title {
        font-family: "Roboto Slab", serif;
        font-size: 3.75rem;
        font-weight: 400;
        color: white; } }
  .seventh__content-box {
    padding: 10px 0px 10px 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    @media screen and (min-width: 1248px) {
      .seventh__content-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start; } }
  .seventh__l-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    flex-basis: 10%;
    height: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: 1.875rem;
    font-weight: 600;
    color: white; }
    @media screen and (min-width: 1248px) {
      .seventh__l-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-basis: 40%;
        height: 100%;
        font-family: "Montserrat", sans-serif;
        font-size: 1.875rem;
        font-weight: 600;
        color: white; } }
  .seventh__number {
    display: block;
    margin-bottom: 20px; }
  .seventh__r-box {
    padding-left: 10px;
    flex-basis: 90%;
    height: 100%;
    letter-spacing: 4px; }
    @media screen and (min-width: 1248px) {
      .seventh__r-box {
        flex-basis: 60%;
        height: 100%;
        letter-spacing: 4px; } }
  .seventh__subtitle {
    letter-spacing: normal;
    cursor: pointer;
    padding-left: 10px;
    padding-top: 2px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Roboto Slab", serif;
    font-size: 0.9375rem;
    font-weight: 200;
    color: #ffd500;
    padding-bottom: 2px;
    border-bottom: 0.5px solid #b9b9b9;
    transition: 0.4s ease all; }
    @media screen and (min-width: 400px) {
      .seventh__subtitle {
        padding-bottom: 5px;
        padding-top: 5px; } }
    @media screen and (min-width: 1025px) {
      .seventh__subtitle:hover {
        padding-left: 10px;
        padding-right: 10px;
        background-color: #ffd500;
        color: #4c9aff; }
      .seventh__subtitle:hover span {
        color: #005aba; } }
    @media screen and (min-width: 1248px) {
      .seventh__subtitle {
        font-family: "Roboto Slab", serif;
        font-size: 1.5625rem;
        font-weight: 200;
        color: #ffd500;
        padding-top: 12px;
        padding-bottom: 12px; } }
  .seventh__finger-svg {
    animation: rise 3s infinite alternate;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    width: 30px;
    height: 30px;
    color: white; }
  .seventh__text {
    transition: 0.4s ease all;
    padding: 10px 10px 10px 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.8125rem;
    font-weight: 300;
    color: white;
    word-spacing: 0.8px;
    border-bottom: 0.5px solid #b9b9b9;
    letter-spacing: 0.4px;
    line-height: 13px; }
    @media screen and (min-width: 480px) {
      .seventh__text {
        line-height: 15px;
        padding: 20px 10px 20px 10px;
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 200;
        color: white; } }
    @media screen and (min-width: 768px) {
      .seventh__text {
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 200;
        color: white;
        border-bottom: 0.5px solid #b9b9b9;
        letter-spacing: 0.5px; } }
    @media screen and (min-width: 1248px) {
      .seventh__text {
        font-family: "Montserrat", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        color: white;
        border-bottom: 0.5px solid #b9b9b9; } }
  .seventh__link-wrapper {
    cursor: pointer;
    padding-left: 0px;
    padding-top: 15px;
    font-family: "Roboto Slab", serif;
    font-size: 1.25rem;
    font-weight: 200;
    color: #ffd500;
    padding-bottom: 15px;
    border-bottom: 0.5px solid #b9b9b9;
    transition: 0.4s ease all; }
    @media screen and (min-width: 1248px) {
      .seventh__link-wrapper {
        font-family: "Roboto Slab", serif;
        font-size: 1.875rem;
        font-weight: 200;
        color: #ffd500;
        padding-top: 30px;
        padding-bottom: 30px; } }
    .seventh__link-wrapper:hover {
      padding-left: 10px;
      background-color: white;
      color: #2684ff; }
  .seventh__link-text {
    display: none; }
  .seventh__contact {
    padding-top: 2px;
    padding-left: 10px;
    font-family: "Roboto Slab", serif;
    font-size: 0.9375rem;
    font-weight: 300;
    color: #ffd500;
    margin-bottom: 2px; }
    @media screen and (min-width: 480px) {
      .seventh__contact {
        padding-top: 10px;
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 300;
        color: #ffd500;
        margin-bottom: 5px; } }
    @media screen and (min-width: 1248px) {
      .seventh__contact {
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 600;
        color: #ffd500;
        margin-bottom: 5px; } }
  .seventh__contact-item {
    text-align: center;
    margin-bottom: 0px;
    flex-basis: 50%; }
    @media screen and (min-width: 480px) {
      .seventh__contact-item {
        text-align: center;
        margin-bottom: 5px;
        flex-basis: 50%; } }
    @media screen and (min-width: 1248px) {
      .seventh__contact-item {
        margin-bottom: 5px;
        flex-basis: 30%; } }
  .seventh__contact-item a {
    text-align: center;
    transition: 0.4s ease all;
    letter-spacing: 0.5px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.625rem;
    font-weight: 400;
    color: white; }
    @media screen and (min-width: 768px) {
      .seventh__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 300;
        color: white;
        letter-spacing: normal; } }
    @media screen and (min-width: 1248px) {
      .seventh__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        color: white;
        letter-spacing: normal; } }
    .seventh__contact-item a:hover {
      color: #ffd500; }
  .seventh__contact-list {
    padding-left: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; }
  .seventh__image {
    display: none; }
    @media screen and (min-width: 1248px) {
      .seventh__image {
        display: block;
        width: 100%; } }

.eight {
  padding-left: 10px;
  padding-right: 5px;
  width: 100%;
  height: 100vh; }
  .eight__title {
    z-index: 2;
    font-family: "Montserrat", sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    color: white;
    text-align: center; }
    @media screen and (min-width: 480px) {
      .eight__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 560px) {
      .eight__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.5625rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 1025px) {
      .eight__title {
        font-family: "Roboto Slab", serif;
        font-size: 2.8125rem;
        font-weight: 400;
        color: white; } }
  .eight__content-box {
    padding: 10px 0px 10px 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    @media screen and (min-width: 1248px) {
      .eight__content-box {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start; } }
  .eight__l-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    flex-basis: 10%;
    height: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: 1.875rem;
    font-weight: 600;
    color: white; }
    @media screen and (min-width: 1248px) {
      .eight__l-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-basis: 40%;
        height: 100%;
        font-family: "Montserrat", sans-serif;
        font-size: 1.875rem;
        font-weight: 600;
        color: white; } }
  .eight__number {
    display: block;
    margin-bottom: 20px; }
  .eight__r-box {
    padding-right: 0px;
    flex-basis: 90%;
    height: 100%;
    letter-spacing: 4px; }
    @media screen and (min-width: 1248px) {
      .eight__r-box {
        flex-basis: 60%;
        height: 100%;
        letter-spacing: 4px; } }
  .eight__subtitle {
    letter-spacing: normal;
    cursor: pointer;
    padding-right: 0px;
    padding-top: 2px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Roboto Slab", serif;
    font-size: 0.9375rem;
    font-weight: 200;
    color: #ffd500;
    padding-bottom: 2px;
    border-bottom: 0.5px solid #b9b9b9;
    transition: 0.4s ease all; }
    @media screen and (min-width: 400px) {
      .eight__subtitle {
        padding-bottom: 5px;
        padding-top: 5px; } }
    @media screen and (min-width: 1025px) {
      .eight__subtitle:hover {
        padding-left: 10px;
        padding-right: 10px;
        background-color: #ffd500;
        color: #4c9aff; }
      .eight__subtitle:hover span {
        color: #005aba; } }
    @media screen and (min-width: 1248px) {
      .eight__subtitle {
        font-family: "Roboto Slab", serif;
        font-size: 1.875rem;
        font-weight: 200;
        color: #ffd500;
        padding-top: 12px;
        padding-bottom: 12px; } }
  .eight__finger-svg {
    animation: rise 3s infinite alternate;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    width: 30px;
    height: 30px;
    color: white; }
  .eight__text p {
    transition: 0.4s ease all;
    padding: 5px 5px 5px 15px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.8125rem;
    font-weight: 300;
    color: white;
    border-bottom: 0.5px solid #b9b9b9;
    letter-spacing: 0.5px;
    line-height: 12px; }
    @media screen and (min-width: 480px) {
      .eight__text p {
        line-height: 17px;
        padding: 10px 10px 10px 10px;
        font-family: "Montserrat", sans-serif;
        font-size: 0.8125rem;
        font-weight: 200;
        color: white; } }
    @media screen and (min-width: 768px) {
      .eight__text p {
        font-family: "Montserrat", sans-serif;
        font-size: 0.8125rem;
        font-weight: 200;
        color: white;
        border-bottom: 0.5px solid #b9b9b9;
        letter-spacing: 0.5px; } }
    @media screen and (min-width: 1248px) {
      .eight__text p {
        font-family: "Montserrat", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        color: white;
        border-bottom: 0.5px solid #b9b9b9; } }
  .eight__link-text {
    display: none; }
  .eight__contact {
    padding-top: 2px;
    padding-left: 10px;
    font-family: "Roboto Slab", serif;
    font-size: 0.9375rem;
    font-weight: 300;
    color: #ffd500;
    margin-bottom: 2px; }
    @media screen and (min-width: 480px) {
      .eight__contact {
        padding-top: 10px;
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 300;
        color: #ffd500;
        margin-bottom: 5px; } }
    @media screen and (min-width: 1248px) {
      .eight__contact {
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 600;
        color: #ffd500;
        margin-bottom: 5px; } }
  .eight__contact-item {
    text-align: center;
    margin-bottom: 0px;
    flex-basis: 50%; }
    @media screen and (min-width: 480px) {
      .eight__contact-item {
        text-align: center;
        margin-bottom: 5px;
        flex-basis: 50%; } }
    @media screen and (min-width: 1248px) {
      .eight__contact-item {
        margin-bottom: 5px;
        flex-basis: 30%; } }
  .eight__contact-item a {
    text-align: center;
    transition: 0.4s ease all;
    letter-spacing: 0.5px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.625rem;
    font-weight: 400;
    color: white; }
    @media screen and (min-width: 768px) {
      .eight__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 300;
        color: white;
        letter-spacing: normal; } }
    @media screen and (min-width: 1248px) {
      .eight__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        color: white;
        letter-spacing: normal; } }
    .eight__contact-item a:hover {
      color: #ffd500; }
  .eight__contact-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 10px; }
  .eight__image {
    background-color: white;
    max-height: 350px;
    border-radius: 10px 10px 0 0;
    display: none; }
    @media screen and (min-width: 1248px) {
      .eight__image {
        display: block;
        width: 80%; } }
  .eight__ajax-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 0 0 10px 10px;
    padding-bottom: 5px;
    flex-wrap: wrap;
    display: none; }
    @media screen and (min-width: 1248px) {
      .eight__ajax-wrapper {
        display: flex;
        width: 80%; } }
  .eight__ajax-image {
    object-fit: contain;
    flex-basis: 50%;
    height: 50px; }

.services {
  padding-top: 250px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 2;
  background-color: rgba(157, 157, 157, 0.2);
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }
  @media screen and (min-width: 350px) {
    .services {
      padding-top: 250px; } }
  @media screen and (min-width: 400px) {
    .services {
      padding-top: 150px; } }
  @media screen and (min-width: 480px) {
    .services {
      background-color: transparent;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 140px;
      z-index: 2;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; } }
  @media screen and (min-width: 730px) {
    .services {
      padding-top: 0px;
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap; } }
  .services__item {
    padding: 0 2px;
    min-height: auto;
    cursor: pointer;
    transition: 0.4s ease all;
    height: 100%;
    padding-top: 0px;
    flex-basis: 40%;
    margin-bottom: 5px;
    background-color: #ffd500;
    border-radius: 5px;
    padding-bottom: 3px;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media screen and (min-width: 350px) {
      .services__item {
        padding-bottom: 10px;
        min-height: 60px;
        flex-basis: 45%; } }
    @media screen and (min-width: 400px) {
      .services__item {
        flex-basis: 32%;
        min-height: 75px; } }
    @media screen and (min-width: 730px) {
      .services__item {
        min-height: 90px; } }
    @media screen and (min-width: 1025px) {
      .services__item {
        background-color: transparent;
        border-radius: 0;
        min-height: 35px; } }
    .services__item:not(:last-of-type) {
      margin-right: 3px;
      border-right: none; }
    .services__item h2 {
      transition: 0.4s ease all; }
    .services__item img {
      transition: 0.4s ease all;
      border: 2px solid transparent; }
    .services__item:hover h2 {
      color: #ffd500; }
    .services__item:hover img {
      border: 2px solid #ffd500; }
    .services__item a {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center; }
    @media screen and (min-width: 480px) {
      .services__item:not(:last-of-type) {
        margin-right: 5px;
        border-right: 2px solid #9d9d9d; } }
  .services__image {
    width: 110px;
    border-radius: 50%;
    display: none; }
    @media screen and (min-width: 1025px) {
      .services__image {
        display: block;
        width: 110px;
        height: 110px;
        border-radius: 50%; } }
  .services__name {
    font-family: "Roboto Slab", serif;
    font-size: 1rem;
    font-weight: 400;
    color: #262626;
    padding-top: 3px; }
    @media screen and (min-width: 350px) {
      .services__name {
        font-family: "Roboto Slab", serif;
        font-size: 0.9375rem;
        font-weight: 400;
        color: #262626;
        padding-top: 10px; } }
    @media screen and (min-width: 730px) {
      .services__name {
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: #262626;
        padding-top: 00px; } }
    @media screen and (min-width: 1025px) {
      .services__name {
        font-family: "Roboto Slab", serif;
        font-size: 1rem;
        font-weight: 400;
        color: white;
        padding-top: 0px; } }
  .services__exit-button {
    display: block;
    flex-basis: 100%;
    padding: 0 20px;
    transition: 0.4s ease all;
    text-align: right;
    margin-bottom: 10px;
    font-family: "Roboto Slab", serif;
    font-size: 1.5625rem;
    font-weight: 400;
    color: white;
    cursor: pointer; }
    .services__exit-button:hover {
      color: #2684ff; }
    @media screen and (min-width: 730px) {
      .services__exit-button {
        display: none; } }

.dots {
  height: 300px;
  width: 30px;
  position: absolute;
  z-index: 9999;
  left: 0px;
  top: calc(50% - 150px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; }
  .dots__item {
    display: block;
    cursor: pointer;
    transition: 0.4s ease all;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #ffd500; }
    .dots__item:hover {
      transform: scale(1.5); }
    @media screen and (min-width: 1025px) {
      .dots__item {
        width: 15px;
        height: 15px; } }

.burger {
  z-index: 99999;
  transition: 0.4s ease all;
  position: absolute;
  top: 14px;
  right: 20px;
  width: 40px;
  height: 30px;
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start; }
  @media screen and (min-width: 1025px) {
    .burger {
      display: none; } }
  .burger span {
    transition: 0.4s ease all;
    width: 100%;
    height: 2px;
    background-color: #ffd500; }
  .burger__active span:nth-child(1) {
    width: 30%; }
  .burger__active span:nth-child(2) {
    width: 60%; }
  .burger__active span:nth-child(3) {
    width: 90%; }
  .burger__menu {
    z-index: 9999;
    transition: 0.4s ease all;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: -150vh;
    right: 0px;
    left: 0px;
    bottom: 0px;
    background: linear-gradient(rgba(0, 0, 0, 0.8) 100%, rgba(0, 0, 0, 0.7) 100%), url("../images/main-logo.jpg");
    background-position: 50% -70px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start; }
    @media screen and (min-width: 1025px) {
      .burger__menu {
        display: none; } }
  .burger__showed {
    top: 0; }
  .burger__list {
    padding-top: 70px;
    width: 100%;
    height: 100%;
    background-color: rgba(38, 38, 38, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    @media screen and (min-width: 400px) {
      .burger__list {
        padding-top: 100px; } }
  .burger__item {
    display: block;
    width: 80%;
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid white; }
    .burger__item:last-of-type {
      margin-bottom: 10px; }
    @media screen and (min-width: 480px) {
      .burger__item {
        margin-bottom: 25px; } }
    .burger__item--form a {
      font-family: "Montserrat", sans-serif;
      font-size: 1.25rem;
      font-weight: 500;
      color: #ffd500;
      padding: 10px 5px;
      border-bottom: 2px solid #ffd500;
      border-left: 2px solid #ffd500;
      border-right: 2px solid transparent;
      border-top: 2px solid transparent;
      transition: 0.4s ease all; }
      .burger__item--form a:hover {
        border-bottom: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid #ffd500;
        border-top: 2px solid #ffd500; }
  .burger__item-link {
    padding: 10px 10px;
    display: block;
    width: 100%;
    height: 100%;
    font-family: "Roboto Slab", serif;
    font-size: 1.5625rem;
    font-weight: 400;
    color: white;
    letter-spacing: 2px; }
  .burger__contacts {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 20px 40px 20px;
    background-color: #262626; }
  .burger__contact-item {
    text-align: left;
    flex-basis: 50%; }
    .burger__contact-item:nth-child(odd) {
      text-align: left;
      padding-right: 00px; }
    .burger__contact-item:nth-child(even) {
      text-align: left;
      padding-left: 00px; }
    @media screen and (min-width: 768px) {
      .burger__contact-item {
        text-align: center;
        flex-basis: 50%; }
        .burger__contact-item:nth-child(odd) {
          text-align: right;
          padding-right: 50px; }
        .burger__contact-item:nth-child(even) {
          text-align: left;
          padding-left: 50px; } }
  .burger__contact-item a {
    text-align: left;
    transition: 0.4s ease all;
    letter-spacing: 0.7px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.6875rem;
    font-weight: 300;
    color: white; }
    @media screen and (min-width: 768px) {
      .burger__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 300;
        color: white;
        letter-spacing: normal; } }
    @media screen and (min-width: 1248px) {
      .burger__contact-item a {
        font-family: "Montserrat", sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        color: white;
        letter-spacing: normal; } }
  .burger__contact-item span {
    display: none; }

.about {
  padding-top: 50px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 20px;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.8) 100%, rgba(0, 0, 0, 0.7) 100%), url("../images/main-logo.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  @media screen and (min-width: 400px) {
    .about {
      padding-top: 100px; } }
  @media screen and (min-width: 1025px) {
    .about {
      padding-top: 150px;
      background: none; } }
  .about__container {
    padding: 0 10px 20px 10px;
    border: 1px solid #ffd500;
    border-radius: 10px;
    height: 100%;
    background-color: rgba(38, 38, 38, 0.8); }
  .about__title-box {
    z-index: 2;
    text-align: center;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 0.5px solid #b9b9b9; }
    @media screen and (min-width: 400px) {
      .about__title-box {
        min-height: 120px; } }
    @media screen and (min-width: 560px) {
      .about__title-box {
        padding-bottom: 0px; } }
    @media screen and (min-width: 1025px) {
      .about__title-box {
        padding-left: 20px;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 0.5px solid #b9b9b9; } }
  .about__title {
    z-index: 2;
    padding-left: 10px;
    padding-top: 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    color: white;
    text-align: center;
    padding-bottom: 10px;
    display: block; }
    @media screen and (min-width: 400px) {
      .about__title {
        padding-top: 10px;
        font-family: "Montserrat", sans-serif;
        font-size: 1.375rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 480px) {
      .about__title {
        padding-top: 0px;
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 560px) {
      .about__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.875rem;
        font-weight: 400;
        color: white;
        display: block; } }
    @media screen and (min-width: 1025px) {
      .about__title {
        padding-left: 20px;
        font-family: "Roboto Slab", serif;
        font-size: 1.875rem;
        font-weight: 400;
        color: white;
        padding-bottom: 0px;
        display: block; } }
  .about__text {
    margin-bottom: 20px;
    padding: 20px 0;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #b9b9b9;
    font-family: "Roboto Slab", serif;
    font-size: 1rem;
    font-weight: 400;
    color: white;
    margin-bottom: 15px; }
    @media screen and (min-width: 560px) {
      .about__text {
        font-family: "Roboto Slab", serif;
        font-size: 1.125rem;
        font-weight: 400;
        color: white;
        margin-bottom: 10px; } }
  .about__link-wrapper {
    letter-spacing: normal;
    cursor: pointer;
    margin-bottom: 20px;
    padding-left: 0px;
    padding-top: 5px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Roboto Slab", serif;
    font-size: 1.875rem;
    font-weight: 200;
    color: #ffd500;
    padding-bottom: 5px;
    transition: 0.4s ease all; }
    @media screen and (min-width: 1025px) {
      .about__link-wrapper:hover {
        padding-left: 10px;
        padding-right: 10px;
        background-color: #ffd500;
        color: #4c9aff; }
      .about__link-wrapper:hover span {
        color: #005aba; } }
    @media screen and (min-width: 1248px) {
      .about__link-wrapper {
        font-family: "Roboto Slab", serif;
        font-size: 1.875rem;
        font-weight: 200;
        color: #ffd500;
        padding-top: 10px;
        padding-bottom: 10px; } }
  .about__finger-svg {
    animation: rise 3s infinite alternate;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    width: 30px;
    height: 30px;
    color: white; }
  .about__list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 5px;
    flex-wrap: wrap;
    margin-bottom: 5px; }
  .about__list--second {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border: none;
    padding: 10px 5px;
    flex-wrap: wrap; }
  .about__list-item {
    flex-basis: 100%;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
    font-family: "Roboto Slab", serif;
    font-size: 1.125rem;
    font-weight: 400;
    color: white; }
    @media screen and (min-width: 520px) {
      .about__list-item {
        flex-basis: 50%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-align: start;
        font-family: "Roboto Slab", serif;
        font-size: 1.0625rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 768px) {
      .about__list-item {
        flex-basis: 25%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: "Roboto Slab", serif;
        font-size: 0.9375rem;
        font-weight: 400;
        color: white; } }
  .about__subtitle {
    border-top: 0.5px solid #ffd500;
    font-family: "Roboto Slab", serif;
    font-size: 0.9375rem;
    font-weight: 400;
    color: #ffd500;
    padding: 0px 5px; }

.glide__track {
  margin: 0 auto;
  width: 100%;
  height: 100%; }
  @media screen and (min-width: 1025px) {
    .glide__track {
      width: 900px;
      height: 600px; } }

.glide__image {
  width: 100%;
  height: 100%; }
  @media screen and (min-width: 1025px) {
    .glide__image {
      width: 900px;
      height: 600px; } }

.glide__arrow {
  display: none !important;
  border: 2px solid #ffd500; }
  @media screen and (min-width: 768px) {
    .glide__arrow {
      display: block !important;
      border: 2px solid white !important;
      background-color: #ffd500;
      color: #ffd500 !important; } }

.prices {
  padding-top: 50px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 20px;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.8) 100%, rgba(0, 0, 0, 0.7) 100%), url("../images/main-logo.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  @media screen and (min-width: 400px) {
    .prices {
      padding-top: 100px; } }
  @media screen and (min-width: 1025px) {
    .prices {
      padding-top: 150px;
      background: none; } }
  .prices__container {
    padding: 0 10px 20px 10px;
    border: 1px solid #ffd500;
    border-radius: 10px;
    height: 100%;
    background-color: rgba(38, 38, 38, 0.8); }
  .prices__title-box {
    margin-bottom: 10px;
    z-index: 2;
    text-align: center;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 0.5px solid #b9b9b9; }
    @media screen and (min-width: 400px) {
      .prices__title-box {
        min-height: 120px; } }
    @media screen and (min-width: 560px) {
      .prices__title-box {
        padding-bottom: 0px; } }
    @media screen and (min-width: 1025px) {
      .prices__title-box {
        padding-left: 20px;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 0.5px solid #b9b9b9; } }
  .prices__title {
    z-index: 2;
    padding-left: 10px;
    padding-top: 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    color: white;
    text-align: center;
    padding-bottom: 10px;
    display: block; }
    @media screen and (min-width: 400px) {
      .prices__title {
        padding-top: 10px;
        font-family: "Montserrat", sans-serif;
        font-size: 1.375rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 480px) {
      .prices__title {
        padding-top: 0px;
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 560px) {
      .prices__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.875rem;
        font-weight: 400;
        color: white;
        display: block; } }
    @media screen and (min-width: 1025px) {
      .prices__title {
        padding-left: 20px;
        font-family: "Roboto Slab", serif;
        font-size: 2.5rem;
        font-weight: 400;
        color: white;
        padding-bottom: 0px;
        display: block; } }
  .prices__text p {
    font-family: "Roboto Slab", serif;
    font-size: 1rem;
    font-weight: 400;
    color: white;
    margin-bottom: 15px; }
    @media screen and (min-width: 560px) {
      .prices__text p {
        font-family: "Roboto Slab", serif;
        font-size: 1.125rem;
        font-weight: 400;
        color: white;
        margin-bottom: 10px; } }
  .prices__text {
    margin-bottom: 20px;
    padding: 20px 0;
    border-bottom: 0.5px solid #b9b9b9; }
  .prices__text-info {
    padding-left: 10px;
    margin-bottom: 5px;
    letter-spacing: 1px;
    position: relative;
    font-family: "Roboto Slab", serif;
    font-size: 1rem;
    font-weight: 300;
    color: white; }
    @media screen and (min-width: 730px) {
      .prices__text-info {
        margin-bottom: 5px;
        letter-spacing: normal;
        font-family: "Roboto Slab", serif;
        font-size: 0.875rem;
        font-weight: 200;
        color: white; } }
    @media screen and (min-width: 1248px) {
      .prices__text-info {
        padding-left: 20px;
        margin-bottom: 10px;
        font-family: "Roboto Slab", serif;
        font-size: 1.125rem;
        font-weight: 400;
        color: white; } }
    .prices__text-info:before {
      content: " ";
      display: block;
      width: 6px;
      height: 6px;
      background-color: #ffd500;
      border-radius: 50%;
      position: absolute;
      left: -3px;
      top: calc(50% - 3px); }
  .prices__subtitle {
    z-index: 2;
    padding-left: 10px;
    padding-top: 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    color: white;
    text-align: center;
    padding-bottom: 10px;
    display: block; }
    @media screen and (min-width: 400px) {
      .prices__subtitle {
        padding-top: 10px;
        font-family: "Montserrat", sans-serif;
        font-size: 1.375rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 480px) {
      .prices__subtitle {
        padding-top: 0px;
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 560px) {
      .prices__subtitle {
        font-family: "Montserrat", sans-serif;
        font-size: 1.875rem;
        font-weight: 400;
        color: white;
        display: block; } }
    @media screen and (min-width: 1025px) {
      .prices__subtitle {
        padding-left: 20px;
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white;
        padding-bottom: 0px;
        display: block;
        margin-bottom: 20px; } }
  .prices__details {
    margin-bottom: 20px;
    padding: 0px 0 20px 0;
    border-bottom: 0.5px solid #b9b9b9; }
  .prices__warning {
    padding-left: 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    color: #ffd500; }
    @media screen and (min-width: 400px) {
      .prices__warning {
        font-family: "Montserrat", sans-serif;
        font-size: 0.75rem;
        font-weight: 400;
        color: #ffd500; } }
    @media screen and (min-width: 480px) {
      .prices__warning {
        font-family: "Montserrat", sans-serif;
        font-size: 0.625rem;
        font-weight: 400;
        color: #ffd500; } }
    @media screen and (min-width: 560px) {
      .prices__warning {
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: #ffd500; } }
    @media screen and (min-width: 1025px) {
      .prices__warning {
        font-family: "Roboto Slab", serif;
        font-size: 0.8125rem;
        font-weight: 400;
        color: #ffd500; } }

.news {
  padding-top: 50px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 20px;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.8) 100%, rgba(0, 0, 0, 0.7) 100%), url("../images/main-logo.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  @media screen and (min-width: 400px) {
    .news {
      padding-top: 100px; } }
  @media screen and (min-width: 1025px) {
    .news {
      padding-top: 150px;
      background: none; } }
  .news__container {
    padding: 0 10px 20px 10px;
    border: 1px solid #ffd500;
    border-radius: 10px;
    height: 100%;
    background-color: rgba(38, 38, 38, 0.8); }
  .news__news-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 30px;
    padding-top: 30px;
    position: relative; }
    .news__news-box:nth-child(odd) {
      flex-direction: column; }
    @media screen and (min-width: 1025px) {
      .news__news-box {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 30px;
        padding-top: 30px;
        position: relative; }
        .news__news-box:nth-child(odd) {
          flex-direction: row-reverse; } }
  .news__border {
    width: 100%;
    height: 7px;
    position: absolute;
    border-radius: 20px;
    bottom: 0;
    background: #0027ff;
    background: linear-gradient(90deg, #0027ff 0%, #fd8f00 70%, #ffd500 100%);
    background-size: 150%;
    animation: gradient 5s ease infinite; }
  .news__news-image {
    position: relative;
    flex-basis: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px; }
    @media screen and (min-width: 1025px) {
      .news__news-image {
        flex-basis: 45%;
        margin-bottom: 0; } }
  .news__image {
    max-width: 300px;
    object-fit: contain; }
    @media screen and (min-width: 420px) {
      .news__image {
        max-width: 400px;
        object-fit: contain; } }
    @media screen and (min-width: 520px) {
      .news__image {
        max-width: 500px;
        object-fit: contain; } }
    @media screen and (min-width: 730px) {
      .news__image {
        max-width: 600px;
        object-fit: contain; } }
  .news__news-text-box {
    padding-left: 0px;
    flex-basis: 55%;
    height: 100%; }
    @media screen and (min-width: 1025px) {
      .news__news-text-box {
        padding-left: 5px; } }
  .news__news-title {
    transition: 0.4s ease all;
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 1.625rem;
    font-weight: 400;
    color: white;
    text-align: center; }
    @media screen and (min-width: 480px) {
      .news__news-title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white;
        text-align: left; } }
    @media screen and (min-width: 560px) {
      .news__news-title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.5625rem;
        font-weight: 400;
        color: white;
        text-align: left; } }
    @media screen and (min-width: 1025px) {
      .news__news-title {
        font-family: "Roboto Slab", serif;
        font-size: 1.875rem;
        font-weight: 400;
        color: white;
        text-align: left; } }
    .news__news-title:hover {
      color: #0065ff; }
  .news__news-text {
    transition: 0.4s ease all;
    padding: 5px 0px 5px 0px;
    font-family: "Montserrat", sans-serif;
    font-size: 1.125rem;
    font-weight: 300;
    color: white;
    border-bottom: 0.5px solid #b9b9b9;
    letter-spacing: 0.2px; }
    @media screen and (min-width: 480px) {
      .news__news-text {
        padding: 5px 5px 5px 5px;
        font-family: "Montserrat", sans-serif;
        font-size: 1.125rem;
        font-weight: 200;
        color: white; } }
    @media screen and (min-width: 768px) {
      .news__news-text {
        font-family: "Montserrat", sans-serif;
        font-size: 1.125rem;
        font-weight: 200;
        color: white;
        padding: 5px 5px 5px 5px;
        border-bottom: 0.5px solid #b9b9b9;
        letter-spacing: 0.5px; } }
    @media screen and (min-width: 1248px) {
      .news__news-text {
        font-family: "Montserrat", sans-serif;
        font-size: 1.125rem;
        font-weight: 300;
        color: white;
        border-bottom: 0.5px solid #b9b9b9; } }
  .news__news-text a {
    font-weight: 400;
    color: #ffd500;
    transition: 0.4s ease all; }
    .news__news-text a:hover {
      color: #0065ff; }
  .news__title-box {
    width: 100%;
    margin-bottom: 20px;
    z-index: 2;
    text-align: center;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 0.5px solid #b9b9b9; }
    @media screen and (min-width: 400px) {
      .news__title-box {
        min-height: 120px; } }
    @media screen and (min-width: 560px) {
      .news__title-box {
        padding-bottom: 0px; } }
    @media screen and (min-width: 1025px) {
      .news__title-box {
        padding-left: 20px;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 0.5px solid #b9b9b9; } }
  .news__title {
    z-index: 2;
    padding-left: 10px;
    padding-top: 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 1.875rem;
    font-weight: 400;
    color: white;
    text-align: center;
    padding-bottom: 10px;
    display: block; }
    @media screen and (min-width: 400px) {
      .news__title {
        padding-top: 10px;
        font-family: "Montserrat", sans-serif;
        font-size: 1.375rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 480px) {
      .news__title {
        padding-top: 0px;
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 560px) {
      .news__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.875rem;
        font-weight: 400;
        color: white;
        display: block; } }
    @media screen and (min-width: 1025px) {
      .news__title {
        padding-left: 20px;
        font-family: "Roboto Slab", serif;
        font-size: 1.875rem;
        font-weight: 400;
        color: white;
        padding-bottom: 0px;
        display: block; } }

.contacts {
  padding-top: 50px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 20px;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.8) 100%, rgba(0, 0, 0, 0.7) 100%), url("../images/main-logo.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  @media screen and (min-width: 400px) {
    .contacts {
      padding-top: 100px; } }
  @media screen and (min-width: 1025px) {
    .contacts {
      padding-top: 150px;
      background: none; } }
  .contacts__container {
    padding: 0 10px 20px 10px;
    border: 1px solid #ffd500;
    border-radius: 10px;
    height: 100%;
    background-color: rgba(38, 38, 38, 0.8); }
  .contacts__title-box {
    z-index: 2;
    text-align: center;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 0.5px solid #b9b9b9;
    margin-bottom: 20px; }
    @media screen and (min-width: 400px) {
      .contacts__title-box {
        min-height: 120px; } }
    @media screen and (min-width: 560px) {
      .contacts__title-box {
        padding-bottom: 0px; } }
    @media screen and (min-width: 1025px) {
      .contacts__title-box {
        padding-left: 20px;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 0.5px solid #b9b9b9; } }
  .contacts__title {
    z-index: 2;
    padding-left: 10px;
    padding-top: 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    color: white;
    text-align: center;
    padding-bottom: 10px;
    display: block; }
    @media screen and (min-width: 400px) {
      .contacts__title {
        padding-top: 10px;
        font-family: "Montserrat", sans-serif;
        font-size: 1.375rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 480px) {
      .contacts__title {
        padding-top: 0px;
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 560px) {
      .contacts__title {
        font-family: "Montserrat", sans-serif;
        font-size: 1.875rem;
        font-weight: 400;
        color: white;
        display: block; } }
    @media screen and (min-width: 1025px) {
      .contacts__title {
        padding-left: 20px;
        font-family: "Montserrat", sans-serif;
        font-size: 1.875rem;
        font-weight: 400;
        color: white;
        padding-bottom: 0px;
        display: block; } }
  .contacts__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    @media screen and (min-width: 1025px) {
      .contacts__content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start; } }
  .contacts__content-wrapper {
    flex-basis: 30%;
    width: 100%; }
  .contacts__map {
    flex-basis: 70%;
    width: 100%;
    padding-left: 0px;
    padding-top: 30px; }
    @media screen and (min-width: 1025px) {
      .contacts__map {
        padding-left: 20px;
        padding-top: 0px; } }
  .contacts__map-holder {
    height: 400px;
    /* The height is 400 pixels */
    width: 100%;
    /* The width is the width of the web page */ }
    @media screen and (min-width: 560px) {
      .contacts__map-holder {
        height: 600px; } }
  .contacts__subtitle {
    text-align: center;
    margin-bottom: 15px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.9375rem;
    font-weight: 400;
    color: #ffd500; }
    @media screen and (min-width: 400px) {
      .contacts__subtitle {
        font-family: "Montserrat", sans-serif;
        font-size: 1.375rem;
        font-weight: 400;
        color: #ffd500; } }
    @media screen and (min-width: 480px) {
      .contacts__subtitle {
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: #ffd500; } }
    @media screen and (min-width: 560px) {
      .contacts__subtitle {
        font-family: "Montserrat", sans-serif;
        font-size: 1.875rem;
        font-weight: 400;
        color: #ffd500; } }
    @media screen and (min-width: 1025px) {
      .contacts__subtitle {
        padding-left: 20px;
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: #ffd500; } }
  .contacts__phone a {
    text-align: center;
    transition: 0.4s ease all;
    letter-spacing: 0.5px;
    font-family: "Roboto Slab", serif;
    font-size: 1rem;
    font-weight: 400;
    color: white; }
    @media screen and (min-width: 768px) {
      .contacts__phone a {
        font-family: "Roboto Slab", serif;
        font-size: 1.125rem;
        font-weight: 300;
        color: white;
        letter-spacing: normal; } }
    @media screen and (min-width: 1248px) {
      .contacts__phone a {
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white;
        letter-spacing: normal; } }
    .contacts__phone a:hover {
      color: #ffd500; }
  .contacts__phone:last-of-type {
    margin-bottom: 30px; }
  .contacts__email a {
    text-align: center;
    transition: 0.4s ease all;
    letter-spacing: 0.5px;
    font-family: "Roboto Slab", serif;
    font-size: 1rem;
    font-weight: 400;
    color: white; }
    @media screen and (min-width: 768px) {
      .contacts__email a {
        font-family: "Roboto Slab", serif;
        font-size: 1.125rem;
        font-weight: 300;
        color: white;
        letter-spacing: normal; } }
    @media screen and (min-width: 1248px) {
      .contacts__email a {
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white;
        letter-spacing: normal; } }
    .contacts__email a:hover {
      color: #ffd500; }
  .contacts__email:last-of-type {
    margin-bottom: 30px; }
  .contacts__address {
    text-align: center;
    transition: 0.4s ease all;
    letter-spacing: 0.5px;
    font-family: "Roboto Slab", serif;
    font-size: 1rem;
    font-weight: 400;
    color: white; }
    @media screen and (min-width: 768px) {
      .contacts__address {
        font-family: "Roboto Slab", serif;
        font-size: 1.125rem;
        font-weight: 300;
        color: white;
        letter-spacing: normal; } }
    @media screen and (min-width: 1248px) {
      .contacts__address {
        font-family: "Roboto Slab", serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white;
        letter-spacing: normal; } }
  .contacts__address:last-of-type {
    margin-bottom: 30px; }
  .contacts__facebook {
    margin-bottom: 15px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.9375rem;
    font-weight: 400;
    color: white; }
    @media screen and (min-width: 400px) {
      .contacts__facebook {
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 480px) {
      .contacts__facebook {
        font-family: "Montserrat", sans-serif;
        font-size: 1.5625rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 560px) {
      .contacts__facebook {
        font-family: "Montserrat", sans-serif;
        font-size: 1.875rem;
        font-weight: 400;
        color: white; } }
    @media screen and (min-width: 1025px) {
      .contacts__facebook {
        transition: 0.4s ease all;
        padding-left: 20px;
        font-family: "Roboto Slab", serif;
        font-size: 1.5625rem;
        font-weight: 400;
        color: white; }
        .contacts__facebook:hover {
          color: #ffd500; } }
    .contacts__facebook span {
      padding-right: 10px;
      color: #ffd500; }

.form {
  width: 400px;
  height: 500px;
  background-color: white;
  padding: 10px 15px 10px 15px;
  border-radius: 10px; }
  .form__wrapper {
    display: none;
    z-index: 999999;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8); }
  .form__wrapper--shown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  .form__exit-button {
    transition: 0.4s ease all;
    text-align: right;
    margin-bottom: 10px;
    font-family: "Roboto Slab", serif;
    font-size: 1.5625rem;
    font-weight: 400;
    color: #262626;
    cursor: pointer; }
    .form__exit-button:hover {
      color: #2684ff; }
  .form__title {
    margin-bottom: 20px;
    text-align: left;
    font-family: "Roboto Slab", serif;
    font-size: 1.375rem;
    font-weight: 300;
    color: #9d9d9d; }
  .form__subtitle {
    margin-bottom: 15px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    color: #9d9d9d; }
    @media screen and (min-width: 480px) {
      .form__subtitle {
        font-family: "Montserrat", sans-serif;
        font-size: 0.9375rem;
        font-weight: 400;
        color: #9d9d9d; } }
    @media screen and (min-width: 560px) {
      .form__subtitle {
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: #9d9d9d; } }
    @media screen and (min-width: 1025px) {
      .form__subtitle {
        font-family: "Montserrat", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        color: #9d9d9d; } }
  .form__input {
    width: 100%;
    height: 50px;
    margin-bottom: 30px;
    font-family: "Montserrat", sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    color: #262626;
    padding-left: 20px;
    border: none;
    border-radius: 30px;
    background-color: #e9edf7; }
  .form__button {
    width: 100%;
    display: block;
    padding: 15px 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background-color: #ffd500;
    font-family: "Montserrat", sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    color: #9d9d9d;
    transition: 0.4s ease all;
    cursor: pointer; }
    .form__button:hover {
      background-color: #0065ff;
      color: white; }
  .form__result {
    transition: 0.4s ease all;
    padding-top: 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.9375rem;
    font-weight: 400;
    color: #ffd500; }

.form-button {
  z-index: 9999991;
  position: fixed;
  width: 100%;
  background-color: #ffd500;
  height: 35px;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s ease all;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .form-button span {
    z-index: 2;
    font-family: "Montserrat", sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    color: #262626;
    margin-bottom: 0px;
    margin-right: 5px; }
  .form-button__square {
    z-index: 1;
    width: 30px;
    height: 30px;
    background-color: #ffd500;
    position: absolute;
    transform: rotate(-45deg);
    left: calc(50% - 15px);
    transition: 0.4s ease all; }
  @media screen and (min-width: 1025px) {
    .form-button {
      z-index: 9999991;
      position: fixed;
      width: 40px;
      background-color: #ffd500;
      height: 100vh;
      left: auto;
      top: 0;
      right: 0;
      bottom: 0;
      transition: 0.4s ease all;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
      .form-button:hover {
        width: 60px; }
      .form-button:hover div {
        transform: rotate(315deg); }
      .form-button span {
        z-index: 2;
        font-family: "Montserrat", sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
        color: #262626;
        margin-bottom: 5px;
        margin-right: 0px; }
      .form-button__square {
        transition: 0.4s ease all;
        z-index: 1;
        width: 50px;
        height: 50px;
        background-color: #ffd500;
        position: absolute;
        transform: rotate(-45deg);
        left: -5px; } }

.margined-letter {
  margin-right: 15px !important; }
  @media screen and (min-width: 1025px) {
    .margined-letter {
      margin-right: 0px !important; } }

.wow {
  visibility: hidden; }
