// Fonts
// @import url("");

// Core
@import "./core/vars";
@import "./core/inheritance";
@import "./core/mixins";
@import "./core/common";

// components-->common

// Components
@import "./src/css/components/ajax";
@import "./src/css/components/main-page/header";
@import "./src/css/components/main-page/footer";
@import "./src/css/components/nav";
@import "./src/css/components/table";
@import "./src/css/components/main-page/first";
@import "./src/css/components/main-page/second";
@import "./src/css/components/main-page/third";
@import "./src/css/components/main-page/fourth";
@import "./src/css/components/main-page/fiveth";
@import "./src/css/components/main-page/sixth";
@import "./src/css/components/main-page/seventh";
@import "./src/css/components/main-page/eight";
@import "./src/css/components/services";
@import "./src/css/components/dots";
@import "./src/css/components/burger";
@import "./src/css/components/about/about";
@import "./src/css/components/prices/prices";
@import "./src/css/components/news/news";
@import "./src/css/components/contacts/contacts";
@import "./src/css/components/form";

.wow {
  visibility: hidden;
}
