.ajax{
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 99999;
    background-color: $white;
    height: 50px;
    @include flex-main();
    border-radius: 10px;
    transition: 0.4s ease all;
    cursor: pointer;
  
    &:hover{
        transition: 0.4s ease all;
        & .ajax__logo{
        width: 160px;    
        }   
       
    }
    &__logo{
        transition: 0.4s ease all;
    width: 150px;    
    }   
}