.news {
  padding-top: 50px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 20px;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.8) 100%, rgba(0, 0, 0, 0.7) 100%),
    url("../images/main-logo.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include media-q($phone) {
    padding-top: 100px;
  }
  @include media-q($tablet-wide) {
    padding-top: 150px;
    background: none;
  }

  &__container {
    padding: 0 10px 20px 10px;
    border: 1px solid $first-page-link-color;
    border-radius: 10px;
    height: 100%;
    background-color: rgba($color: $dark, $alpha: 0.8);
  }
  &__news-box {
    width: 100%;
    @include flex-main(column, flex-start, center);
    padding-bottom: 30px;
    padding-top: 30px;
    position: relative;
    &:nth-child(odd) {
      flex-direction: column;
    }
    @include media-q($tablet-wide) {
      width: 100%;
      @include flex-main(row, space-between, center);
      padding-bottom: 30px;
      padding-top: 30px;
      position: relative;
      &:nth-child(odd) {
        flex-direction: row-reverse;
      }
    }
  }
  &__border {
    width: 100%;
    height: 7px;
    position: absolute;
    border-radius: 20px;
    bottom: 0;
    background: rgb(0, 39, 255);
    background: linear-gradient(
      90deg,
      rgba(0, 39, 255, 1) 0%,
      rgb(253, 143, 0) 70%,
      rgba(255, 213, 0, 1) 100%
    );
    background-size: 150%;
    animation: gradient 5s ease infinite;
  }
  &__news-image {
    position: relative;
    flex-basis: auto;
    @include flex-main;
    margin-bottom: 30px;
 
    @include media-q($tablet-wide) {
    
      flex-basis: 45%;
      margin-bottom: 0;
    }
  }
  &__image{
    max-width: 300px;
    object-fit: contain;
    @include media-q($phone-s-wide){
      max-width: 400px;
      object-fit: contain;
      }
    @include media-q($phone-w-wide){
    max-width: 500px;
    object-fit: contain;
    }
    @include media-q($tablet-small){
      max-width: 600px;
      object-fit: contain;
    }
  }
  &__news-text-box {
    padding-left: 0px;
    flex-basis: 55%;
    height: 100%;
    @include media-q($tablet-wide) {
      padding-left: 5px;
    }
  }
  &__news-title {
    transition: 0.4s ease all;
    margin-bottom: 20px;
    @include font($secondary-font, 26px, 400, $white);
    text-align: center;

    @include media-q($phone-wide) {
      @include font($secondary-font, 20px, 400, $white);
      text-align: left;
    }

    @include media-q($phablet) {
      @include font($secondary-font, 25px, 400, $white);
      text-align: left;
    }

    @include media-q($tablet-wide) {
      @include font($main-font, 30px, 400, $white);
      text-align: left;
    }
    &:hover {
      color: $fourth-page-color;
    }
  }
  &__news-text {
    transition: 0.4s ease all;
    padding: 5px 0px 5px 0px;
    @include font($secondary-font, 18px, 300, $first-page-text-color);

    border-bottom: 0.5px solid $light;
    letter-spacing: 0.2px;

    @include media-q($phone-wide) {
      padding: 5px 5px 5px 5px;
      @include font($secondary-font, 18px, 200, $first-page-text-color);
    }
    @include media-q($tablet) {
      @include font($secondary-font, 18px, 200, $first-page-text-color);
      padding: 5px 5px 5px 5px;
      border-bottom: 0.5px solid $light;
      letter-spacing: 0.5px;
    }
    @include media-q($desktop) {
      @include font($secondary-font, 18px, 300, $first-page-text-color);

      border-bottom: 0.5px solid $light;
    }
  }
  &__news-text a {
    font-weight: 400;
    color: $first-page-link-color;
    transition: 0.4s ease all;
    &:hover {
      color: $fourth-page-color;
    }
  }
  &__title-box {
    width: 100%;
    margin-bottom: 20px;
    z-index: 2;
    text-align: center;
    min-height: 40px;
    @include flex-main(column, center, center);
    border-bottom: 0.5px solid $light;

    @include media-q($phone) {
      min-height: 120px;
    }

    @include media-q($phablet) {
      padding-bottom: 0px;
    }

    @include media-q($tablet-wide) {
      padding-left: 20px;
      min-height: 100px;
      @include flex-main(column, center, center);
      border-bottom: 0.5px solid $light;
    }
  }

  &__title {
    z-index: 2;
    padding-left: 10px;
    padding-top: 10px;
    @include font($secondary-font, 30px, 400, $white);
    text-align: center;
    padding-bottom: 10px;
    display: block;

    @include media-q($phone) {
      padding-top: 10px;
      @include font($secondary-font, 22px, 400, $white);
    }

    @include media-q($phone-wide) {
      padding-top: 0px;
      @include font($secondary-font, 20px, 400, $white);
    }

    // display: none;
    @include media-q($phablet) {
      @include font($secondary-font, 30px, 400, $white);
      display: block;
    }

    @include media-q($tablet-wide) {
      padding-left: 20px;
      @include font($main-font, 30px, 400, $white);
      // padding-top: 60px;
      padding-bottom: 0px;
      display: block;
    }
  }
}

