.fourth {
  padding-left: 10px;
  padding-right: 5px;
  width: 100%;
  height: 100vh;

  &__title {
    z-index: 2;

    @include font($secondary-font, 18px, 400, $white);
    text-align: center;

    @include media-q($phone-wide) {
      @include font($secondary-font, 20px, 400, $white);
    }

    @include media-q($phablet) {
      @include font($secondary-font, 25px, 400, $white);
    }

    @include media-q($tablet-wide) {
      @include font($main-font, 60px, 400, $white);
    }
  }
  &__secondtitle {
    letter-spacing: normal;
    text-align: center;
    padding-right: 0px;
    padding-top: 2px;
    padding-left: 10px;
    @include flex-main(row, space-between, center);
    @include font($main-font, 15px, 200, $fourth-page-text-color);
    padding-bottom: 5px;
    border-bottom: 0.5px solid $light;
    transition: 0.4s ease all;
    @include media-q($phone) {
      padding-bottom: 5px;
      padding-top: 5px;
    }
    @include media-q($desktop) {
      @include font($main-font, 22px, 200, $fourth-page-text-color);
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
  &__content-box {
    padding: 10px 0px 10px 10px;
    width: 100%;
    height: 100%;
    @include flex-main(column, flex-start, center);
    @include media-q($desktop) {
      @include flex-main(row-reverse, space-between, flex-start);
    }
  }
  &__l-box {
    @include flex-main(row, center, center);
    padding-left: 10px;
    flex-basis: 10%;
    height: 100%;
    @include font($secondary-font, 30px, 600, $second-page-text-color);
    @include media-q($desktop) {
      @include flex-main(column, flex-start, center);
      flex-basis: 40%;
      height: 100%;
      @include font($secondary-font, 30px, 600, $second-page-text-color);
    }
  }
  &__number {
    display: block;
    margin-bottom: 20px;
  }
  &__r-box {
    padding-right: 0px;
    flex-basis: 90%;
    height: 100%;
    letter-spacing: 4px;
    @include media-q($desktop) {
      flex-basis: 60%;
      height: 100%;
      letter-spacing: 4px;
    }
  }
  &__subtitle {
    letter-spacing: normal;
    cursor: pointer;
    padding-right: 0px;
    padding-top: 2px;
    padding-left: 10px;
    @include flex-main(row, space-between, center);
    @include font($main-font, 15px, 200, $header-link-color);
    padding-bottom: 2px;
    border-bottom: 0.5px solid $light;
    transition: 0.4s ease all;
    @include media-q($phone) {
      padding-bottom: 5px;
      padding-top: 5px;
    }
    &:hover {
    }
    &:hover span {
    }
    @include media-q($tablet-wide) {
      &:hover {
        padding-left: 10px;
        padding-right: 10px;
        background-color: $header-text-color;
        color: $second-page-color;
      }
      &:hover span {
        color: $main-color;
      }
    }
    @include media-q($desktop) {
      @include font($main-font, 30px, 200, $header-link-color);
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
  &__finger-svg {
    animation: rise 3s infinite alternate;
    @include flex-main();
    font-weight: 600;
    width: 30px;
    height: 30px;
    color: $white;
  }
  &__text p {
    transition: 0.4s ease all;
    padding: 5px 5px 5px 15px;
    @include font($secondary-font, 13px, 300, $second-page-text-color);
    border-bottom: 0.5px solid $light;
    letter-spacing: 0.5px;
    line-height: 12px;
    @include media-q($phone-wide) {
      line-height: 17px;
      padding: 10px 10px 10px 10px;
      @include font($secondary-font, 13px, 200, $second-page-text-color);
    }
    @include media-q($tablet) {
      @include font($secondary-font, 13px, 200, $second-page-text-color);
      border-bottom: 0.5px solid $light;
      letter-spacing: 0.5px;
    }
    @include media-q($desktop) {
      @include font($secondary-font, 16px, 400, $second-page-text-color);
      border-bottom: 0.5px solid $light;
    }
  }

  &__link-wrapper {
    cursor: pointer;
    padding-right: 0px;
    padding-top: 15px;
    @include font($main-font, 20px, 200, $second-page-link-color);
    padding-bottom: 15px;
    border-bottom: 0.5px solid $light;
    transition: 0.4s ease all;
    @include media-q($desktop) {
      @include font($main-font, 30px, 200, $second-page-link-color);
      padding-top: 30px;
      padding-bottom: 30px;
    }
    &:hover {
      padding-right: 10px;
      background-color: $second-page-text-color;
      color: $second-page-color;
    }
  }
  &__link-text {
    display: none;
  }
  &__contact {
    padding-top: 2px;
    padding-left: 10px;
    @include font($main-font, 15px, 300, $second-page-link-color);
    margin-bottom: 2px;
    @include media-q($phone-wide) {
      padding-top: 10px;
      @include font($main-font, 20px, 300, $second-page-link-color);
      margin-bottom: 5px;
    }
    @include media-q($desktop) {
      @include font($main-font, 20px, 600, $second-page-link-color);
      margin-bottom: 5px;
    }
  }
  &__contact-item {
    text-align: center;
    margin-bottom: 0px;
    flex-basis: 50%;
    @include media-q($phone-wide) {
      text-align: center;
      margin-bottom: 5px;
      flex-basis: 50%;
    }
    @include media-q($desktop) {
      margin-bottom: 5px;
      flex-basis: 30%;
    }
  }
  &__contact-item a {
    text-align: center;
    transition: 0.4s ease all;
    letter-spacing: 0.5px;
    @include font($secondary-font, 10px, 400, $first-page-text-color);
    @include media-q($tablet) {
      @include font($secondary-font, 15px, 300, $first-page-text-color);
      letter-spacing: normal;
    }
    @include media-q($desktop) {
      @include font($secondary-font, 14px, 400, $first-page-text-color);
      letter-spacing: normal;
    }
    &:hover {
      color: $first-page-link-color;
    }
  }
  &__contact-list {
    width: 100%;
    @include flex-main(row, space-between, center);
    flex-wrap: wrap;
    padding-left: 10px;
  }
  &__image {
    display: none;
    @include media-q($desktop) {
      display: block;
      width: 100%;
    }
  }
}
