@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?5w0k50");
  src: url("../fonts/icomoon.eot?5w0k50#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?5w0k50") format("truetype"),
    url("../fonts/icomoon.woff?5w0k50") format("woff"),
    url("../fonts/icomoon.svg?5w0k50#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pointing-up-hand-svgrepo-com:before {
  content: "\e900";
}
.icon-phone:before {
  content: "\e901";
}
.icon-envelop:before {
  content: "\e945";
}
.icon-location2:before {
  content: "\e948";
}
.icon-facebook2:before {
  content: "\ea91";
}

// Natural colors
$white: white;
$white-smoke: whitesmoke;
$light: #b9b9b9;
$grey: #9d9d9d;
$dark: #262626;
$red: red;
$main-color: #005aba;
$secondary-color: #ffd500;
$input-color: #e9edf7;

$header-color: #7eb1ec;
$header-text-color: #ffd500;
$header-link-color: #ffd500;

$first-page-color: #6554c0;
$first-page-text-color: white;
$first-page-link-color: #ffd500;

$second-page-color: #4c9aff;
$second-page-text-color: white;
$second-page-link-color: #ffd500;

$third-page-color: #2684ff;
$third-page-text-color: white;
$third-page-link-color: #ffd500;

$fourth-page-color: #0065ff;
$fourth-page-text-color: white;
$fourth-page-link-color: #ffd500;

$fiveth-page-color: #8777d9;
$fiveth-page-text-color: white;
$fiveth-page-link-color: #ffd500;

// Bacground colors

// Button colors

// Text colors

// Border colors

// Fonts
$secondary-font: "Montserrat", sans-serif;
$main-font: "Roboto Slab", serif;
$phone-small: 350px;
$phone: 400px;
$phone-s-wide: 420px;
$phone-wide: 480px;
$phone-w-wide: 520px;
$phablet: 560px;
$tablet-small: 730px;
$tablet: 768px;
$tablet-lb: 769px;

$tablet-wide: 1025px;
$desktop: 1248px;
$desktop-wide: 1440px;
