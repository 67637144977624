.header {
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  height: 100vh;
  background: none;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @include media-q($tablet-wide) {
    background-image: none;
  }
  &__container {
    height: 100%;
  }
  &__title-box {
    z-index: 2;
    // padding-left: 10px;
    text-align: center;
    min-height: 60px;
    @include flex-main(column, center, center);
    border-bottom: 0.5px solid $light;
    @include media-q($phone) {
      min-height: 120px;
    }
    @include media-q($phablet) {
      padding-bottom: 0px;
    }
    @include media-q($tablet-wide) {
      padding-left: 20px;
      min-height: 165px;
      @include flex-main(column, flex-start, center);
      border-bottom: 0.5px solid $light;
    }
  }
  &__title {
    padding-top: 0;
    width: 100%;
    z-index: 2;
    padding-left: 10px;
    @include font($secondary-font, 15px, 400, $white);
    text-align: center;
    padding-bottom: 4px;
    display: block;

    @include media-q($phone) {
      @include font($secondary-font, 22px, 400, $white);
    }
    @include media-q($phone-wide) {
      @include font($secondary-font, 20px, 400, $white);
    }
    // display: none;
    @include media-q($phablet) {
      @include font($secondary-font, 30px, 400, $white);
      display: block;
    }

    @include media-q($tablet-wide) {
      padding-top: 30px;
      padding-left: 20px;
      @include font($main-font, 30px, 400, $white);
      // padding-top: 60px;
      padding-bottom: 10px;
      display: block;
    }
  }
  &__content-box {
    padding: 10px;
    width: 100%;
    height: 50vh;
    @include flex-main(column, flex-start, flex-start);
    @include media-q($tablet-small) {
      @include flex-main(row, space-between, center);
    }
  }
  &__l-box {
    background-image: url("../images/main-logo.jpg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    flex-basis: 40%;
    height: 100%;
    display: none;
    @include font($secondary-font, 30px, 600, $header-text-color);
    @include media-q($tablet-small) {
      display: block;
    }
    @include media-q($desktop) {
      display: block;
      flex-basis: 17%;
      height: 90%;
      @include font($secondary-font, 30px, 600, $header-text-color);
    }
  }
  &__number {
    display: block;
    margin-bottom: 20px;
  }
  &__r-box {
    padding-left: 20px;
    flex-basis: 60%;
    height: 100%;
    letter-spacing: 4px;
    @include media-q($desktop) {
      flex-basis: 83%;
      height: 100%;
      letter-spacing: 4px;
    }
  }
  &__subtitle {
    @include font($main-font, 12px, 400, $header-text-color);
    margin-bottom: 10px;
    @include media-q($desktop) {
      @include font($main-font, 20px, 400, $header-text-color);
      margin-bottom: 10px;
    }
  }
  &__text {
    padding-top: 0px;
    letter-spacing: 1px;
    @include font($main-font, 12px, 300, $white);
    padding-bottom: 10px;
    border-bottom: 0.5px solid $light;
    @include media-q($tablet-small) {
      padding-top: 20px;
      letter-spacing: normal;
      @include font($main-font, 15px, 200, $white);
      padding-bottom: 20px;
      border-bottom: 0.5px solid $light;
    }

    @include media-q($desktop) {
      padding-top: 5px;
      @include font($main-font, 14px, 400, $white);
      padding-bottom: 5px;
      border-bottom: 0.5px solid $light;
    }
  }
  &__text-info {
    margin-bottom: 5px;
    letter-spacing: 1px;
    position: relative;
    @include font($secondary-font, 13px, 300, $white);

    @include media-q($tablet-small) {
      margin-bottom: 5px;
      letter-spacing: normal;
      @include font($secondary-font, 14px, 200, $white);
    }

    @include media-q($desktop) {
      margin-bottom: 5px;
      @include font($secondary-font, 14px, 300, $white);
      letter-spacing: 1.5px;
    }
    &:nth-child(1) {
      &:before {
        display: none;
      }
    }
    &:nth-child(2) {
      &:before {
        display: none;
      }
    }
    &:before {
      content: " ";
      display: none;
      width: 6px;
      height: 6px;
      background-color: $secondary-color;
      border-radius: 50%;
      position: absolute;
      left: -10px;
      top: calc(50% - 3px);
      @include media-q($phone-wide) {
        content: " ";
        display: block;
        width: 6px;
        height: 6px;
        background-color: $secondary-color;
        border-radius: 50%;
        position: absolute;
        left: -10px;
        top: calc(50% - 3px);
      }
    }
    &:nth-child(1) {
      display: none;
      @include media-q($desktop) {
        display: block;
      }
    }
    &:nth-child(2) {
      display: none;
      @include media-q($desktop) {
        display: block;
      }
    }
  }
  &__mvs {
    @include font($secondary-font, 12px, 400, $white);
    @include media-q($tablet-wide) {
      @include font($secondary-font, 16px, 400, $white);
    }
  }
  &__link-wrapper {
    letter-spacing: normal;
    cursor: pointer;
    padding-left: 0px;
    padding-top: 5px;
    padding-right: 10px;
    @include flex-main(row, space-between, center);
    @include font($main-font, 15px, 200, $header-link-color);
    padding-bottom: 5px;
    border-bottom: 0.5px solid $light;
    transition: 0.4s ease all;
    &:hover {
    }
    &:hover span {
    }
    @include media-q($tablet-wide) {
      &:hover {
        padding-left: 10px;
        padding-right: 10px;
        background-color: $header-text-color;
        color: $second-page-color;
      }
      &:hover span {
        color: $main-color;
      }
    }
    @include media-q($desktop) {
      @include font($main-font, 30px, 200, $header-link-color);
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  &__finger-svg {
    animation: rise 3s infinite alternate;
    @include flex-main();
    font-weight: 600;
    width: 30px;
    height: 30px;
    color: $white;
  }
  &__link-text {
    display: none;
  }
}
