table {
    width: 100%;
    font-family: $main-font;
    font-size: 12px;
    border-radius: 10px;
    border-spacing: 0;
    text-align: center;
 
    @include media-q($tablet){
        font-size: 15px;

    }
    }
    th {
    background: $second-page-link-color;
    color: white;
    text-shadow: 0 1px 1px #2D2020;
    padding: 10px 20px;
    }
    th, td {
    border-style: solid;
    border-width: 0 1px 1px 0;
    border-color: $light;
    }
    th:first-child, td:first-child {
    text-align: left;
    }
    th:first-child {
    border-top-left-radius: 10px;
    }
    th:last-child {
    border-top-right-radius: 10px;
    border-right: none;
    }
    td {
        text-align: left;
    padding: 10px 20px;
    background: $white;
    }
    tr:last-child td:first-child {
    border-radius: 0 0 0 10px;
    }
    tr:last-child td:last-child {
    border-radius: 0 0 10px 0;
    }
    tr td:last-child {
    border-right: none;
    }
    
