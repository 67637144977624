.first {
  padding-left: 10px;
  padding-right: 5px;
  width: 100%;
  height: 100vh;

  &__title {
    z-index: 2;
    @include font($secondary-font, 18px, 400, $white);
    text-align: center;

    @include media-q($phone-wide) {
      @include font($secondary-font, 20px, 400, $white);
    }

    @include media-q($phablet) {
      @include font($secondary-font, 25px, 400, $white);
    }

    @include media-q($tablet-wide) {
      @include font($main-font, 60px, 400, $white);
    }
  }
  &__content-box {
    padding: 10px 0px 10px 10px;
    width: 100%;
    height: 100%;
    @include flex-main(column, flex-start, center);
    @include media-q($desktop) {
      @include flex-main(row, space-between, flex-start);
    }
  }
  &__l-box {
    @include flex-main(row, center, center);
    padding-right: 10px;
    flex-basis: 10%;
    height: 100%;
    @include font($secondary-font, 30px, 600, $first-page-text-color);
    @include media-q($desktop) {
      @include flex-main(column, flex-start, center);
      flex-basis: 40%;
      height: 100%;
      @include font($secondary-font, 30px, 600, $first-page-text-color);
    }
  }
  &__number {
    display: block;
    margin-bottom: 20px;
  }
  &__r-box {
    padding-left: 10px;
    flex-basis: 90%;
    height: 100%;
    letter-spacing: 4px;
    @include media-q($desktop) {
      flex-basis: 60%;
      height: 100%;
      letter-spacing: 4px;
    }
  }
  &__subtitle {
    letter-spacing: normal;
    cursor: pointer;
    padding-left: 10px;
    padding-top: 2px;
    padding-right: 10px;
    @include flex-main(row, space-between, center);
    @include font($main-font, 15px, 200, $header-link-color);
    padding-bottom: 2px;
    border-bottom: 0.5px solid $light;
    transition: 0.4s ease all;
    @include media-q($phone) {
      padding-bottom: 5px;
      padding-top: 5px;
    }
    &:hover {
    }
    &:hover span {
    }
    @include media-q($tablet-wide) {
      &:hover {
        padding-left: 10px;
        padding-right: 10px;
        background-color: $header-text-color;
        color: $second-page-color;
      }
      &:hover span {
        color: $main-color;
      }
    }
    @include media-q($desktop) {
      @include font($main-font, 30px, 200, $header-link-color);
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
  &__subtext {
    transition: 0.4s ease all;
    padding: 2px 10px 2px 10px;
    @include font($secondary-font, 12px, 300, $first-page-text-color);
    word-spacing: 0.8px;
    border-bottom: 0.5px solid $light;
    letter-spacing: 0.4px;
    line-height: 13px;
    display: none;
    @include media-q($phone-wide) {
      display: block;
      line-height: 15px;
      padding: 20px 10px 20px 10px;
      @include font($secondary-font, 12px, 200, $first-page-text-color);
    }
    @include media-q($tablet) {
      @include font($secondary-font, 15px, 200, $first-page-text-color);

      border-bottom: 0.5px solid $light;
      letter-spacing: 0.5px;
    }
    @include media-q($desktop) {
      @include font($secondary-font, 16px, 400, $first-page-text-color);

      border-bottom: 0.5px solid $light;
    }
  }
  &__finger-svg {
    animation: rise 3s infinite alternate;
    @include flex-main();
    font-weight: 600;
    width: 30px;
    height: 30px;
    color: $white;
  }
  &__text {
    transition: 0.4s ease all;
    padding: 10px 10px 10px 10px;
    @include font($secondary-font, 13px, 300, $first-page-text-color);
    word-spacing: 0.8px;
    border-bottom: 0.5px solid $light;
    letter-spacing: 0.4px;
    line-height: 13px;
    @include media-q($phone-wide) {
      line-height: 15px;
      padding: 20px 10px 20px 10px;
      @include font($secondary-font, 15px, 200, $first-page-text-color);
    }
    @include media-q($tablet) {
      @include font($secondary-font, 15px, 200, $first-page-text-color);

      border-bottom: 0.5px solid $light;
      letter-spacing: 0.5px;
    }
    @include media-q($desktop) {
      @include font($secondary-font, 16px, 400, $first-page-text-color);

      border-bottom: 0.5px solid $light;
    }
  }

  &__link-text {
    display: none;
  }
  &__contact {
    padding-top: 2px;
    padding-left: 10px;
    @include font($main-font, 15px, 300, $first-page-link-color);
    margin-bottom: 2px;
    @include media-q($phone-wide) {
      padding-top: 10px;
      @include font($main-font, 20px, 300, $first-page-link-color);
      margin-bottom: 5px;
    }
    @include media-q($desktop) {
      @include font($main-font, 20px, 600, $first-page-link-color);
      margin-bottom: 5px;
    }
  }
  &__contact-item {
    text-align: center;
    margin-bottom: 0px;
    flex-basis: 50%;
    @include media-q($phone-wide) {
      text-align: center;
      margin-bottom: 5px;
      flex-basis: 50%;
    }
    @include media-q($desktop) {
      margin-bottom: 5px;
      flex-basis: 30%;
    }
  }
  &__contact-item a {
    text-align: center;
    transition: 0.4s ease all;
    letter-spacing: 0.5px;
    @include font($secondary-font, 10px, 400, $first-page-text-color);
    @include media-q($tablet) {
      @include font($secondary-font, 15px, 300, $first-page-text-color);
      letter-spacing: normal;
    }
    @include media-q($desktop) {
      @include font($secondary-font, 14px, 400, $first-page-text-color);
      letter-spacing: normal;
    }
    &:hover {
      color: $first-page-link-color;
    }
  }
  &__contact-list {
    padding-left: 10px;
    width: 100%;
    @include flex-main(row, space-between, center);
    flex-wrap: wrap;
  }
  &__image {
    display: none;
    @include media-q($desktop) {
      display: block;
      height: auto;
      width: 100%;
    }
    @include media-q($desktop-wide) {
      display: block;
      height: 500px;
      width: 100%;
    }
  }
}
