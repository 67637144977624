.services {
  padding-top: 250px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 2;
  background-color: rgba($color: $grey, $alpha: 0.2);
  // height: 100px;
  width: 100%;
  position: absolute;
  @include flex-main(row, center, center);
  flex-wrap: wrap;
  @include media-q($phone-small) {
    padding-top: 250px;
  }
  @include media-q($phone) {
    padding-top: 150px;
  }
  @include media-q($phone-wide) {
    background-color: transparent;
    padding-left: 0px;
    padding-right: 0px;

    padding-top: 140px;

    z-index: 2;

    @include flex-main(row, center, center);
  }
  @include media-q($tablet-small) {
    padding-top: 0px;
    position: relative;
    height: 100%;
    width: 100%;
    @include flex-main(row, space-between, center);
    flex-wrap: nowrap;
    // padding-top: 50px;
  }
  @include media-q($tablet-wide) {
  }

  &__item {
    padding: 0 2px;
    min-height: auto;
    cursor: pointer;
    transition: 0.4s ease all;
    height: 100%;
    padding-top: 0px;
    flex-basis: 40%;
    margin-bottom: 5px;
    background-color: $header-link-color;
    border-radius: 5px;
    padding-bottom: 3px;
    min-height: 60px;
    @include flex-main(column, center, center);
    @include media-q($phone-small) {
      padding-bottom: 10px;
      min-height: 60px;
      flex-basis: 45%;
    }

    @include media-q($phone) {
      flex-basis: 32%;
      min-height: 75px;
    }
    @include media-q($tablet-small) {
      min-height: 90px;
    }
    @include media-q($tablet-wide) {
      background-color: transparent;
      border-radius: 0;
      min-height: 35px;
    }

    &:not(:last-of-type) {
      margin-right: 3px;
      border-right: none;
    }
    & h2 {
      transition: 0.4s ease all;
    }
    & img {
      transition: 0.4s ease all;
      border: 2px solid transparent;
    }
    &:hover h2 {
      color: $header-link-color;
    }
    &:hover img {
      border: 2px solid $header-link-color;
    }
    & a {
      width: 100%;
      height: 100%;
      @include flex-main(column, flex-start, center);
    }
    @include media-q($phone-wide) {
      &:not(:last-of-type) {
        margin-right: 5px;
        border-right: 2px solid $grey;
      }
    }
  }
  &__image {
    width: 110px;

    border-radius: 50%;
    display: none;
    @include media-q($tablet-wide) {
      display: block;
      width: 110px;
      height: 110px;
      border-radius: 50%;
    }
  }
  &__name {
    @include font($main-font, 16px, 400, $dark);
    padding-top: 3px;
    @include media-q($phone-small) {
      @include font($main-font, 15px, 400, $dark);
      padding-top: 10px;
    }

    @include media-q($tablet-small) {
      @include font($main-font, 20px, 400, $dark);
      padding-top: 00px;
    }
    @include media-q($tablet-wide) {
      @include font($main-font, 16px, 400, $white);
      padding-top: 0px;
    }
  }
  &__exit-button {
    display: block;
    flex-basis: 100%;
    padding: 0 20px;
    transition: 0.4s ease all;
    text-align: right;
    margin-bottom: 10px;
    @include font($main-font, 25px, 400, $white);
    cursor: pointer;
    &:hover {
      color: $third-page-color;
    }

    @include media-q($tablet-small) {
      display: none;
    }
  }
}
