.contacts {
  padding-top: 50px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 20px;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.8) 100%, rgba(0, 0, 0, 0.7) 100%),
    url("../images/main-logo.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include media-q($phone) {
    padding-top: 100px;
  }
  @include media-q($tablet-wide) {
    padding-top: 150px;
    background: none;
  }

  &__container {
    padding: 0 10px 20px 10px;
    border: 1px solid $first-page-link-color;
    border-radius: 10px;
    height: 100%;
    background-color: rgba($color: $dark, $alpha: 0.8);
  }
  &__title-box {
    z-index: 2;
    text-align: center;
    min-height: 40px;
    @include flex-main(column, center, center);
    border-bottom: 0.5px solid $light;
    margin-bottom: 20px;
    @include media-q($phone) {
      min-height: 120px;
    }

    @include media-q($phablet) {
      padding-bottom: 0px;
    }

    @include media-q($tablet-wide) {
      padding-left: 20px;
      min-height: 100px;
      @include flex-main(column, center, center);
      border-bottom: 0.5px solid $light;
    }
  }

  &__title {
    z-index: 2;
    padding-left: 10px;
    padding-top: 10px;
    @include font($secondary-font, 18px, 400, $white);
    text-align: center;
    padding-bottom: 10px;
    display: block;

    @include media-q($phone) {
      padding-top: 10px;
      @include font($secondary-font, 22px, 400, $white);
    }

    @include media-q($phone-wide) {
      padding-top: 0px;
      @include font($secondary-font, 20px, 400, $white);
    }

    // display: none;
    @include media-q($phablet) {
      @include font($secondary-font, 30px, 400, $white);
      display: block;
    }

    @include media-q($tablet-wide) {
      padding-left: 20px;
      @include font($secondary-font, 30px, 400, $white);
      // padding-top: 60px;
      padding-bottom: 0px;
      display: block;
    }
  }

  &__content {
    width: 100%;
    @include flex-main(column, flex-start, center);
    @include media-q($tablet-wide) {
      @include flex-main(row, flex-start, flex-start);
    }
  }
  &__content-wrapper {
    flex-basis: 30%;
    width: 100%;
  }
  &__map {
    flex-basis: 70%;
    width: 100%;
    padding-left: 0px;
    padding-top: 30px;
    @include media-q($tablet-wide) {
      padding-left: 20px;
      padding-top: 0px;
    }
  }
  &__map-holder {
    height: 400px;
    /* The height is 400 pixels */
    width: 100%;
    /* The width is the width of the web page */
    @include media-q($phablet) {
      height: 600px;
    }
  }
  &__subtitle {
    text-align: center;
    margin-bottom: 15px;
    @include font($secondary-font, 15px, 400, $secondary-color);

    @include media-q($phone) {
      @include font($secondary-font, 22px, 400, $secondary-color);
    }

    @include media-q($phone-wide) {
      @include font($secondary-font, 20px, 400, $secondary-color);
    }

    // display: none;
    @include media-q($phablet) {
      @include font($secondary-font, 30px, 400, $secondary-color);
    }

    @include media-q($tablet-wide) {
      padding-left: 20px;
      @include font($secondary-font, 20px, 400, $secondary-color);
    }
  }

  &__phone a {
    text-align: center;
    transition: 0.4s ease all;
    letter-spacing: 0.5px;
    @include font($main-font, 16px, 400, $first-page-text-color);
    @include media-q($tablet) {
      @include font($main-font, 18px, 300, $first-page-text-color);
      letter-spacing: normal;
    }
    @include media-q($desktop) {
      @include font($main-font, 20px, 400, $first-page-text-color);
      letter-spacing: normal;
    }
    &:hover {
      color: $first-page-link-color;
    }
  }
  &__phone:last-of-type {
    margin-bottom: 30px;
  }
  &__email a {
    text-align: center;
    transition: 0.4s ease all;
    letter-spacing: 0.5px;
    @include font($main-font, 16px, 400, $first-page-text-color);
    @include media-q($tablet) {
      @include font($main-font, 18px, 300, $first-page-text-color);
      letter-spacing: normal;
    }
    @include media-q($desktop) {
      @include font($main-font, 20px, 400, $first-page-text-color);
      letter-spacing: normal;
    }
    &:hover {
      color: $first-page-link-color;
    }
  }
  &__email:last-of-type {
    margin-bottom: 30px;
  }
  &__address {
    text-align: center;
    transition: 0.4s ease all;
    letter-spacing: 0.5px;
    @include font($main-font, 16px, 400, $first-page-text-color);
    @include media-q($tablet) {
      @include font($main-font, 18px, 300, $first-page-text-color);
      letter-spacing: normal;
    }
    @include media-q($desktop) {
      @include font($main-font, 20px, 400, $first-page-text-color);
      letter-spacing: normal;
    }
  }
  &__address:last-of-type {
    margin-bottom: 30px;
  }
  &__facebook {
    margin-bottom: 15px;
    @include font($secondary-font, 15px, 400, $white);
    @include media-q($phone) {
      @include font($secondary-font, 20px, 400, $white);
    }

    @include media-q($phone-wide) {
      @include font($secondary-font, 25px, 400, $white);
    }

    // display: none;
    @include media-q($phablet) {
      @include font($secondary-font, 30px, 400, $white);
    }

    @include media-q($tablet-wide) {
      transition: 0.4s ease all;
      padding-left: 20px;
      @include font($main-font, 25px, 400, $white);
      &:hover {
        color: $first-page-link-color;
      }
    }
    & span {
      padding-right: 10px;
      color: $secondary-color;
    }
  }
}
