.nav {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0 65px;
  z-index: 99999;
  @include flex-main(row, space-between, center);
  @include media-q($phone) {
    padding: 0 50px;
  }
  &__wrapper {
    width: 100%;
    z-index: 99999;
    @include flex-main(column, flex-start, center);
    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom: 2px solid $light;

    @include media-q($phone) {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    @include media-q($tablet-wide) {
      @include flex-main(row, space-between, center);
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
  &__phone {
    padding-bottom: 5px;
    @include font($main-font, 17px, 500, $white);
    & a {
      color: $white;
      transition: 0.4s ease all;
    }
    &:hover a {
      color: $secondary-color;
    }
    @include media-q($phone) {
      @include font($main-font, 22px, 500, $white);
    }
  }
  &__logo {
    padding-top: 5px;
    @include flex-main(row, flex-start, center);
    margin-bottom: 0px;
    @include media-q($phone) {
      padding-top: 0px;
    }
    @include media-q($phone-wide) {
      @include flex-main(row, flex-start, center);
      margin-bottom: 0px;
    }
    @include media-q($tablet-small) {
      margin-bottom: 0px;
    }
    @include media-q($tablet-wide) {
      margin-bottom: 0px;
    }
  }
  &__logo-image {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 60px;
    margin-right: 10px;
    display: none;
    @include media-q($phone) {
      display: block;
      width: 90px;
      margin-right: 30px;
    }
  }
  &__list {
    flex-wrap: wrap;
    @include flex-main(row, space-between, center);
    transition: 0.4s ease all;
    display: none;
    @include media-q($tablet-wide) {
      flex-wrap: wrap;
      @include flex-main(row, space-between, center);
      transition: 0.4s ease all;
    }
  }
  &__item {
    text-align: center;
    margin-right: 0px;
    margin-bottom: 15px;
    transition: 0.4s ease all;
    & a {
      @include font($main-font, 15px, 400, $white);
      transition: 0.4s ease all;
    }
    &:hover a {
      color: $secondary-color;
    }
    @include media-q($tablet-small) {
      margin: 0;
      margin-right: 30px;
      @include font($secondary-font, 17px, 500, $white);
    }
    &--form a {
      @include font($secondary-font, 20px, 500, $second-page-link-color);
      padding: 10px 5px;
      border-bottom: 2px solid $second-page-link-color;
      border-left: 2px solid $second-page-link-color;
      border-right: 2px solid transparent;
      border-top: 2px solid transparent;
      transition: 0.4s ease all;
      &:hover {
        border-bottom: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid $second-page-link-color;
        border-top: 2px solid $second-page-link-color;
      }
    }
    &:last-of-type a {
      font-weight: 600;
    }
  }
}
