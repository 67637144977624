.about {
  padding-top: 50px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 20px;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.8) 100%, rgba(0, 0, 0, 0.7) 100%),
    url("../images/main-logo.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include media-q($phone) {
    padding-top: 100px;
  }
  @include media-q($tablet-wide) {
    padding-top: 150px;
    background: none;
  }

  &__container {
    padding: 0 10px 20px 10px;
    border: 1px solid $first-page-link-color;
    border-radius: 10px;
    height: 100%;
    background-color: rgba($color: $dark, $alpha: 0.8);
  }

  &__title-box {
    z-index: 2;
    text-align: center;
    min-height: 40px;
    @include flex-main(column, center, center);
    border-bottom: 0.5px solid $light;

    @include media-q($phone) {
      min-height: 120px;
    }

    @include media-q($phablet) {
      padding-bottom: 0px;
    }

    @include media-q($tablet-wide) {
      padding-left: 20px;
      min-height: 100px;
      @include flex-main(column, center, center);
      border-bottom: 0.5px solid $light;
    }
  }

  &__title {
    z-index: 2;
    padding-left: 10px;
    padding-top: 10px;
    @include font($secondary-font, 18px, 400, $white);
    text-align: center;
    padding-bottom: 10px;
    display: block;

    @include media-q($phone) {
      padding-top: 10px;
      @include font($secondary-font, 22px, 400, $white);
    }

    @include media-q($phone-wide) {
      padding-top: 0px;
      @include font($secondary-font, 20px, 400, $white);
    }

    // display: none;
    @include media-q($phablet) {
      @include font($secondary-font, 30px, 400, $white);
      display: block;
    }

    @include media-q($tablet-wide) {
      padding-left: 20px;
      @include font($main-font, 30px, 400, $white);
      // padding-top: 60px;
      padding-bottom: 0px;
      display: block;
    }
  }

  &__text {
    margin-bottom: 20px;
    padding: 20px 0;
    padding-bottom: 10px;
    border-bottom: 0.5px solid $light;
    @include font($main-font, 16px, 400, $first-page-text-color);
    margin-bottom: 15px;
    @include media-q($phablet) {
      @include font($main-font, 18px, 400, $first-page-text-color);
      margin-bottom: 10px;
    }
  }

  &__link-wrapper {
    letter-spacing: normal;
    cursor: pointer;
    margin-bottom: 20px;
    padding-left: 0px;
    padding-top: 5px;
    padding-right: 10px;
    @include flex-main(row, space-between, center);
    @include font($main-font, 30px, 200, $header-link-color);
    padding-bottom: 5px;

    transition: 0.4s ease all;
    &:hover {
    }
    &:hover span {
    }
    @include media-q($tablet-wide) {
      &:hover {
        padding-left: 10px;
        padding-right: 10px;
        background-color: $header-text-color;
        color: $second-page-color;
      }
      &:hover span {
        color: $main-color;
      }
    }
    @include media-q($desktop) {
      @include font($main-font, 30px, 200, $header-link-color);
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  &__finger-svg {
    animation: rise 3s infinite alternate;
    @include flex-main();
    font-weight: 600;
    width: 30px;
    height: 30px;
    color: $white;
  }

  &__list {
    width: 100%;
    @include flex-main(row, flex-start, flex-start);

    padding: 10px 5px;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }
  &__list--second {
    width: 100%;
    @include flex-main(row, flex-start, flex-start);
    border: none;
    padding: 10px 5px;
    flex-wrap: wrap;
  }
  &__list-item {
    flex-basis: 100%;
    padding-bottom: 5px;
    @include flex-main(row, flex-start, center);
    text-align: start;
    @include font($main-font, 18px, 400, $white);
    @include media-q($phone-w-wide) {
      flex-basis: 50%;
      @include flex-main(row, flex-start, center);
      text-align: start;
      @include font($main-font, 17px, 400, $white);
    }
    @include media-q($tablet) {
      flex-basis: 25%;

      @include flex-main;
      text-align: center;
      @include font($main-font, 15px, 400, $white);
    }
  }
  &__subtitle {
    border-top: 0.5px solid $first-page-link-color;
    @include font($main-font, 15px, 400, $second-page-link-color);
    padding: 0px 5px;
  }
}

//SLIDER
.glide {
  &__track {
    margin: 0 auto;
    width: 100%;
    height: 100%;

    @include media-q($tablet-wide) {
      width: 900px;
      height: 600px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;

    @include media-q($tablet-wide) {
      width: 900px;
      height: 600px;
    }
  }

  &__arrow {
    display: none !important;
    border: 2px solid $second-page-link-color;
    @include media-q($tablet) {
      display: block !important;
      border: 2px solid $white !important;
      background-color: $second-page-link-color;
      color: $second-page-link-color !important;
    }
  }
}
