.form {
  &__wrapper {
    display: none;
    z-index: 999999;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.8);
  }
  &__wrapper--shown {
    @include flex-main;
  }
  width: 400px;
  height: 500px;
  background-color: $white;
  padding: 10px 15px 10px 15px;
  border-radius: 10px;

  &__exit-button {
    transition: 0.4s ease all;
    text-align: right;
    margin-bottom: 10px;
    @include font($main-font, 25px, 400, $dark);
    cursor: pointer;
    &:hover {
      color: $third-page-color;
    }
  }
  &__title {
    margin-bottom: 20px;
    text-align: left;
    @include font($main-font, 22px, 300, $grey);
  }
  &__subtitle {
    margin-bottom: 15px;
    @include font($secondary-font, 14px, 400, $grey);

    @include media-q($phone-wide) {
      @include font($secondary-font, 15px, 400, $grey);
    }

    @include media-q($phablet) {
      @include font($secondary-font, 20px, 400, $grey);
    }

    @include media-q($tablet-wide) {
      @include font($secondary-font, 20px, 400, $grey);
    }
  }
  &__input {
    width: 100%;
    height: 50px;
    margin-bottom: 30px;
    @include font($secondary-font, 20px, 400, $dark);
    padding-left: 20px;
    border: none;
    border-radius: 30px;
    background-color: $input-color;
  }
  &__button {
    width: 100%;
    display: block;
    padding: 15px 30px;
    @include flex-main;
    border-radius: 30px;
    background-color: $second-page-link-color;
    @include font($secondary-font, 20px, 400, $grey);
    transition: 0.4s ease all;
    cursor: pointer;

    &:hover {
      background-color: $fourth-page-color;
      color: $white;
    }
  }
  &__result {
    transition: 0.4s ease all;
    padding-top: 20px;
    @include font($secondary-font, 15px, 400, $second-page-link-color);
  }
}

.form-button {
  z-index: 9999991;
  position: fixed;
  width: 100%;
  background-color: $second-page-link-color;
  height: 35px;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s ease all;
  @include flex-main(row, center, center);
  cursor: pointer;
  &:hover {
  }
  & span {
    z-index: 2;
    @include font($secondary-font, 20px, 600, $dark);
    margin-bottom: 0px;
    margin-right: 5px;
  }
  &__square {
    z-index: 1;
    width: 30px;
    height: 30px;
    background-color: $second-page-link-color;
    position: absolute;
    transform: rotate(-45deg);
    left: calc(50% - 15px);
    transition: 0.4s ease all;
  }
  @include media-q($tablet-wide) {
    z-index: 9999991;
    position: fixed;
    width: 40px;
    background-color: $second-page-link-color;
    height: 100vh;
    left: auto;
    top: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s ease all;
    @include flex-main(column, center, center);
    cursor: pointer;
    &:hover {
      width: 60px;
    }
    &:hover div {
      transform: rotate(315deg);
    }
    & span {
      z-index: 2;
      @include font($secondary-font, 24px, 600, $dark);
      margin-bottom: 5px;
      margin-right: 0px;
    }
    &__square {
      transition: 0.4s ease all;
      z-index: 1;
      width: 50px;
      height: 50px;
      background-color: $second-page-link-color;
      position: absolute;
      transform: rotate(-45deg);
      left: -5px;
    }
  }
}
.margined-letter {
  margin-right: 15px !important;
  @include media-q($tablet-wide) {
    margin-right: 0px !important;
  }
}
