.dots {
  height: 300px;
  width: 30px;
  position: absolute;
  z-index: 9999;
  left: 0px;
  top: calc(50% - 150px);
  @include flex-main(column, space-between, center);
  &__item {
    display: block;
    cursor: pointer;
    transition: 0.4s ease all;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid $header-link-color;
    &:hover {
      transform: scale(1.5);
    }
    @include media-q($tablet-wide) {
      width: 15px;
      height: 15px;
    }
  }
}
