.burger {
  z-index: 99999;
  transition: 0.4s ease all;
  position: absolute;
  top: 14px;
  right: 20px;
  width: 40px;
  height: 30px;
  display: block;
  @include flex-main(column, space-between, flex-start);
  @include media-q($tablet-wide) {
    display: none;
  }
  & span {
    transition: 0.4s ease all;
    width: 100%;
    height: 2px;
    background-color: $header-link-color;
  }
  &__active span:nth-child(1) {
    width: 30%;
  }
  &__active span:nth-child(2) {
    width: 60%;
  }
  &__active span:nth-child(3) {
    width: 90%;
  }
  &__menu {
    // padding-top: 100px;
    z-index: 9999;
    transition: 0.4s ease all;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: -150vh;
    right: 0px;
    left: 0px;
    bottom: 0px;
    background: linear-gradient(
        rgba(0, 0, 0, 0.8) 100%,
        rgba(0, 0, 0, 0.7) 100%
      ),
      url("../images/main-logo.jpg");
    background-position: 50% -70px;
    background-size: cover;
    background-repeat: no-repeat;
    @include flex-main(column, space-between, flex-start);
    @include media-q($tablet-wide) {
      display: none;
    }
  }
  &__showed {
    top: 0;
  }
  &__list {
    padding-top: 70px;
    width: 100%;
    height: 100%;
    background-color: rgba($color: $dark, $alpha: 0.2);
    @include flex-main(column, flex-start, center);
    @include media-q($phone) {
      padding-top: 100px;
    }
  }
  &__item {
    display: block;
    width: 80%;
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid $fiveth-page-text-color;
    &:last-of-type {
      margin-bottom: 10px;
    }
    @include media-q($phone-wide) {
      margin-bottom: 25px;
    }
    &--form a {
      @include font($secondary-font, 20px, 500, $second-page-link-color);
      padding: 10px 5px;
      border-bottom: 2px solid $second-page-link-color;
      border-left: 2px solid $second-page-link-color;
      border-right: 2px solid transparent;
      border-top: 2px solid transparent;
      transition: 0.4s ease all;
      &:hover {
        border-bottom: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid $second-page-link-color;
        border-top: 2px solid $second-page-link-color;
      }
    }
  }
  &__item-link {
    padding: 10px 10px;
    display: block;
    width: 100%;
    height: 100%;
    // background-color: rgba($dark, $alpha: 0.9);
    @include font($main-font, 25px, 400, $white);
    letter-spacing: 2px;
  }
  &__contacts {
    @include flex-main(row, center, center);
    flex-wrap: wrap;
    padding: 5px 20px 40px 20px;
    background-color: rgba($color: $dark, $alpha: 1);
  }
  &__contact-item {
    text-align: left;
    flex-basis: 50%;
    &:nth-child(odd) {
      text-align: left;
      padding-right: 00px;
    }
    &:nth-child(even) {
      text-align: left;
      padding-left: 00px;
    }
    @include media-q($tablet) {
      text-align: center;
      flex-basis: 50%;
      &:nth-child(odd) {
        text-align: right;
        padding-right: 50px;
      }
      &:nth-child(even) {
        text-align: left;
        padding-left: 50px;
      }
    }
  }
  &__contact-item a {
    text-align: left;
    transition: 0.4s ease all;
    letter-spacing: 0.7px;
    @include font($secondary-font, 11px, 300, $first-page-text-color);
    @include media-q($tablet) {
      @include font($secondary-font, 15px, 300, $first-page-text-color);
      letter-spacing: normal;
    }
    @include media-q($desktop) {
      @include font($secondary-font, 14px, 400, $first-page-text-color);
      letter-spacing: normal;
    }
  }
  &__contact-item span {
    display: none;
  }
}
