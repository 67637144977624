.footer {
  position: relative;
  width: 100%;
  min-height: 150px;
  padding: 0 10px;
  background-color: rgba($color: black, $alpha: 0.9);
  @include media-q($tablet-wide) {
    padding: 0 50px;
    background-color: rgba($color: #292929, $alpha: 0.9);
  }
  &__container {
    padding: 20px 0;
    width: 100%;
    height: 100%;
    border-top: 2px solid $light;
    // @include flex-main(column, space-between, center);
  }
  &__copyright {
    @include font($secondary-font, 11px, 400, $light);
    text-align: center;
  }
  &__contacts {
    @include flex-main(row, center, center);
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  &__contact-item {
    text-align: center;
    flex-basis: 50%;
    &:nth-child(odd){
      text-align: center;
      padding-right: 00px;
    }
    &:nth-child(even){
      text-align: center;
      padding-left: 00px;
    }
    @include media-q($tablet){
      text-align: center;
      flex-basis: 50%;
      
    }
  }
  &__contact-item a {
    text-align: center;
    transition: 0.4s ease all;
    letter-spacing: 0.5px;
    @include font($secondary-font, 10px, 400, $first-page-text-color);
    @include media-q($tablet) {
      @include font($secondary-font, 15px, 300, $first-page-text-color);
      letter-spacing: normal;
    }
    @include media-q($desktop) {
      @include font($secondary-font, 14px, 400, $first-page-text-color);
      letter-spacing: normal;
    }
    &:hover {
      color: $first-page-link-color;
    }
  }
}
